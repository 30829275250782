import React from 'react';

import cx from 'classnames';

import styles from './header.skeleton.module.scss';

const HeaderSkeleton = () => {
  return (
    <div className={styles.headerSkeleton}>
      <div className={cx(styles.content, styles.headline)} />
      <div className={cx(styles.content, styles.subtitle)} />
      <div className={cx(styles.content, styles.ctaButton)} />
    </div>
  );
};

export default HeaderSkeleton;
