import { Environment } from '@vivino/js-web-common';

import { apiGet, apiPost } from './apiClient';
import { getCurrentEnv } from './helpers/environmentHelper';

const getJsWebHost = () => {
  const env = getCurrentEnv();

  if (env === Environment.Development) {
    return process.env.JS_WEB_HOST;
  }
};

// README if you are moving this to js-web-common:
// this client uses getCurrentEnv() because getEnvironment() from js-web-common
// won't work on ruby-web
// The reason is there is no webpack env for testing on ruby-web so it falls back to production config.
// if you are moving apiGetJsWeb to js-web-common make sure env.ts
// uses window.__PRELOADED_ENV__ for ruby-web
const JS_WEB_HOST = getJsWebHost() || '';
const JS_WEB_API_PATH = '/webapi';

export const apiGetJsWeb = <T>({ url, ...restArgs }): Promise<T> =>
  apiGet({ url: `${JS_WEB_HOST}${JS_WEB_API_PATH}${url}`, ...restArgs });

export const apiPostJsWeb = <T>({ url, body }): Promise<T> =>
  apiPost({ url: `${JS_WEB_HOST}${JS_WEB_API_PATH}${url}`, body });
