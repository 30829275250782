import { Location } from '@components/icons/Location/Location';
import { Note } from '@components/icons/Note/Note';
import { Cash } from '@components/icons/shop/Cash';
import Star from '@components/Star/Star';
import TruncatedText, { NumTruncateLines } from '@components/TruncatedText/TruncatedText';
import UserReview from '@components/UserReview/UserReview';
import UserVintagePrice from '@components/UserVintagePrice/UserVintagePrice';
import { Typography, TypographyType } from '@vivino/js-react-common-ui';
import { RubylibFullUserVintage, useUserContext } from '@vivino/js-web-common';
import cx from 'classnames';
import React, { ReactNode } from 'react';

import styles from './cellarUserData.module.scss';

function UserDataListItem({
  icon = null,
  children,
  innerClassName,
}: {
  icon?: ReactNode;
  children: ReactNode;
  innerClassName?: string;
}) {
  return (
    <li className={styles.listItem}>
      <div className={cx(styles.innerItem, innerClassName)}>
        {icon}
        {children}
      </div>
    </li>
  );
}

interface CellarUserDataProps {
  userVintage: RubylibFullUserVintage;
  truncateItems?: boolean;
}

export default function CellarUserData({
  userVintage,
  truncateItems = false,
}: CellarUserDataProps) {
  const { review, personal_note: personalNote, scan_location, price } = userVintage;

  const { user } = useUserContext();
  const avatar = user?.image?.location;

  return (
    <div className={styles.cellarUserData}>
      <ol className={styles.userData}>
        {review && (
          <UserDataListItem
            innerClassName={truncateItems ? '' : styles.innerItemTop}
            icon={
              <div className={styles.avatarWithStar}>
                <img src={avatar} className={styles.avatar} alt={'test'} />
                <Star rating={review.rating} />
              </div>
            }
          >
            <UserReview review={review} truncate={truncateItems} />
          </UserDataListItem>
        )}

        {personalNote && (
          <UserDataListItem
            innerClassName={truncateItems ? '' : styles.innerItemTop}
            icon={<Note />}
          >
            {truncateItems ? (
              <TruncatedText numLines={NumTruncateLines.One}>
                <Typography type={TypographyType.BodyMedium}>{personalNote}</Typography>
              </TruncatedText>
            ) : (
              <Typography type={TypographyType.BodyMedium}>{personalNote}</Typography>
            )}
          </UserDataListItem>
        )}

        {scan_location && (
          <UserDataListItem icon={<Location />}>
            {truncateItems ? (
              <TruncatedText numLines={NumTruncateLines.One}>
                <Typography type={TypographyType.BodyMedium}>{scan_location.name}</Typography>
              </TruncatedText>
            ) : (
              <Typography type={TypographyType.BodyMedium}>{scan_location.name}</Typography>
            )}
          </UserDataListItem>
        )}

        {price && (
          <UserDataListItem icon={<Cash />}>
            {truncateItems ? (
              <TruncatedText numLines={NumTruncateLines.One}>
                <UserVintagePrice price={price} />
              </TruncatedText>
            ) : (
              <UserVintagePrice price={price} />
            )}
          </UserDataListItem>
        )}
      </ol>
    </div>
  );
}
