import { Typography, TypographyType } from '@vivino/js-react-common-ui';
import { CountryFlagIcon48, RubyLibFullRegion } from '@vivino/js-web-common';
import React from 'react';

import styles from './region.module.scss';

interface RegionProps {
  region: RubyLibFullRegion;
}

export default function Region({ region }: RegionProps) {
  const { name, country } = region;

  return (
    <div className={styles.region}>
      <div className={styles.flag}>
        <CountryFlagIcon48
          countryCode={country.code}
          countryName={country.name}
          className={styles.flagIcon}
        />
      </div>
      <Typography type={TypographyType.BodyMedium} className={styles.name}>
        {name}, {country.name}
      </Typography>
    </div>
  );
}
