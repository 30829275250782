import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './layout.scss';

const Layout = (props) => {
  const { marginSmall = false, overflow = false, className = '', dataTestId = '' } = props;
  return (
    <div
      className={cx(className, styles.outer, {
        [styles.marginSmall]: marginSmall,
        [styles.overflow]: overflow,
      })}
      data-testid={dataTestId}
    >
      <div className={styles.inner}>{props.children}</div>
    </div>
  );
};

Layout.propTypes = {
  marginSmall: PropTypes.bool,
  overflow: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  dataTestId: PropTypes.string,
};

export default Layout;
