import React from 'react';

import {
  CalendarStar,
  CalendarCheck,
  CalendarQuestion,
  CalendarWarning,
  CalendarCritical,
} from '@components/icons/mini/Calendar/Calendar';
import { DrinkingWindowStatus } from '@lib/drinking_window/drinking_window';

interface DrinkingWindowIconProps {
  status: DrinkingWindowStatus;
}

const Icon = ({ status, ...rest }: DrinkingWindowIconProps) => {
  switch (status) {
    case DrinkingWindowStatus.DrinkAtYourPace:
      return <CalendarQuestion {...rest} />;
    case DrinkingWindowStatus.Hold:
      return <CalendarWarning {...rest} />;
    case DrinkingWindowStatus.DrinkOrHold:
      return <CalendarCheck {...rest} />;
    case DrinkingWindowStatus.DrinkNow:
      return <CalendarStar {...rest} />;
    case DrinkingWindowStatus.PastItsPeak:
      return <CalendarCritical {...rest} />;
  }
};

export default function DrinkingWindowIcon({ status }: DrinkingWindowIconProps) {
  return <Icon status={status} data-testid="drinking-window-icon" />;
}
