import Region from '@components/Region/Region';
import TruncatedText, { NumTruncateLines } from '@components/TruncatedText/TruncatedText';
import { Typography, TypographyType } from '@vivino/js-react-common-ui';
import { RubyLibFullVintage, vintageUrl } from '@vivino/js-web-common';
import React from 'react';

import styles from './vintageInfo.module.scss';

interface VintageInfoProps {
  vintage: RubyLibFullVintage;
}

export default function VintageInfo({ vintage }: VintageInfoProps) {
  const { wine, year } = vintage;
  const { name: wineName, region } = wine;

  const vintageName = () => {
    if (vintage.name.includes('U.V.')) {
      return wineName;
    }

    if (vintage.name.includes('N.V.')) {
      return `${wineName} N.V.`;
    }

    return `${wineName} ${year}`;
  };

  const winery = wine.winery || wine.light_winery;

  return (
    <div className={styles.vintageInfo}>
      <div>
        {winery && (
          <TruncatedText numLines={NumTruncateLines.One}>
            <Typography type={TypographyType.BodyMedium} className={styles.wineryName}>
              {winery.name}
            </Typography>
          </TruncatedText>
        )}
      </div>
      <div>
        <a
          onClick={(e) => e.stopPropagation()}
          href={vintageUrl({ vintage })}
          className={styles.vintageLink}
        >
          <TruncatedText numLines={NumTruncateLines.Two}>
            <Typography type={TypographyType.TitleExtraLarge} className={styles.wineName}>
              {vintageName()}
            </Typography>
          </TruncatedText>
        </a>
      </div>
      {region && <Region region={region} />}
    </div>
  );
}
