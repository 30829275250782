import React, { ReactNode } from 'react';

import { Typography, TypographyType } from '@vivino/js-react-common-ui';

import styles from './checkoutCard.module.scss';

interface CheckoutCardProps {
  headline?: ReactNode | string;
  children: ReactNode;
  dataTestId?: string;
}

const CheckoutCard = ({ headline = '', children, dataTestId }: CheckoutCardProps) => {
  return (
    <div className={styles.checkoutCard} data-testid={dataTestId}>
      <div className={styles.content}>
        {headline && <div className={styles.headline}>
          <Typography className={styles.heading} type={TypographyType.BodyLarge} component="h1">
            {headline}
          </Typography>
        </div>}
        {children}
      </div>
    </div>
  );
};

export default CheckoutCard;
