import {
  ErrorBoundary,
  RubyLibFullVintage,
  captureException,
  isSignedIn,
} from '@vivino/js-web-common';
import React, { useEffect, useState } from 'react';
import { fetchUserVintagesByVintageId } from 'vivino-js/api/userVintages';

import AddToCellar from './components/AddToCellar/AddToCellar';
import AddToCellarSkeleton from './components/AddToCellar/AddToCellar.skeleton';
import AddToWishlist from './components/AddToWishlist/AddToWishlist';
import AddToWishlistSkeleton from './components/AddToWishlist/AddToWishlist.skeleton';
import style from './userVintageActionButtons.module.scss';

interface UserVintageActionButtonsProps {
  vintage: RubyLibFullVintage;
}

export default function UserVintageActionButtons({ vintage }: UserVintageActionButtonsProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [userVintage, setUserVintage] = useState();

  function onUserVintageChange(userVintage) {
    setUserVintage(userVintage);
  }

  useEffect(() => {
    if (isSignedIn()) {
      fetchUserVintagesByVintageId({ vintageId: vintage.id })
        .then(({ user_vintages }) => {
          setUserVintage(user_vintages?.[0]);
        })
        .catch((error) => {
          captureException(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <div className={style.userVintageActionButtons}>
        <AddToWishlistSkeleton />
        <AddToCellarSkeleton />
      </div>
    );
  }

  return (
    <div className={style.userVintageActionButtons}>
      <ErrorBoundary>
        <AddToWishlist vintageId={vintage.id} userVintage={userVintage} />
      </ErrorBoundary>

      <ErrorBoundary>
        <AddToCellar
          vintage={vintage}
          userVintage={userVintage}
          handleUpdateUserVintage={onUserVintageChange}
        />
      </ErrorBoundary>
    </div>
  );
}
