import Rating from '@components/Rating/Rating';
import TruncatedText, { NumTruncateLines } from '@components/TruncatedText/TruncatedText';
import { Typography, TypographyType } from '@vivino/js-react-common-ui';
import { RubyLibVintageReview } from '@vivino/js-web-common';
import React from 'react';

import styles from './userReview.module.scss';

interface UserReviewProps {
  review: RubyLibVintageReview;
  truncate?: boolean;
}

export default function UserReview({ review, truncate = false }: UserReviewProps) {
  const { rating } = review;

  if (truncate) {
    return (
      <Typography
        type={TypographyType.BodyMedium}
        component="div"
        className={styles.truncatedUserReview}
      >
        <Rating rating={rating} />
        <TruncatedText numLines={NumTruncateLines.One} className={styles.truncatedText}>
          <span dangerouslySetInnerHTML={{ __html: review.tagged_note }}></span>
        </TruncatedText>
      </Typography>
    );
  }

  return (
    <Typography type={TypographyType.BodyMedium} component="div">
      <Rating rating={rating} />
      <span dangerouslySetInnerHTML={{ __html: review.tagged_note }}></span>
    </Typography>
  );
}
