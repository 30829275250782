import React from 'react';

const VerticalFlagOffIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M18.75,21V4.8931A1.8423,1.8423,0,0,0,16.9752,3h-9.95A1.8423,1.8423,0,0,0,5.25,4.8931V21L12,15.6Z"
      fill="none"
      stroke="#1e1e1e"
      strokeLinecap="round"
      strokeLinejoin="round"
      fillRule="evenodd"
    />
  </svg>
);

export default VerticalFlagOffIcon;
