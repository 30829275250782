import { RubyLibCaseSelection } from 'app/javascript/types/rubyLibApi';

export const mixedCaseBottleImages = ({ caseContent }: { caseContent: RubyLibCaseSelection[] }) => {
  return caseContent.reduce((memo, { item }) => {
    const image = item.vintage.image;
    const typeId = item.vintage.wine.type_id;

    // Push two images as the mixed case offers two bottles of each vintage
    memo.push({ typeId, image });
    memo.push({ typeId, image });

    return memo;
  }, []);
};
