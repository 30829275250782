import { BottleSize, Image, calculateBottleImageUrl } from '@vivino/js-web-common';
import cx from 'classnames';
import React from 'react';

import styles from './mixCaseBottles.scss';

interface MixCaseBottlesProps {
  className?: string;
  bottles: {
    image: Image;
    typeId: number;
  }[];
  size?: BottleSize;
}

const MixCaseBottles = ({ className, bottles, size = BottleSize.Small }: MixCaseBottlesProps) => {
  return (
    <div className={cx(styles.bottlesContainer, className)}>
      {bottles.map(({ image, typeId }, index) => (
        <img
          className={cx(styles.image, styles[`image-${index + 1}`])}
          src={calculateBottleImageUrl(image, size, typeId)}
          key={`image-${index}`}
        />
      ))}
    </div>
  );
};

export default MixCaseBottles;
