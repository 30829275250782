import { useCellarContext } from '@components/Cellar/CellarContext/CellarContext';
import CellarDrawerContent from '@components/Cellar/CellarDrawerContent/CellarDrawerContent';
import { Drawer } from '@mui/material';
import { Button, ButtonTheme, WineCellarIcon, useBreakpoint } from '@vivino/js-react-common-ui';
import { FullUserVintage, RubyLibFullVintage, useAppContext, useI18N } from '@vivino/js-web-common';
import React, { useEffect } from 'react';
import { track } from 'vivino-js/analytics';

import styles from './addToCellar.module.scss';
import AddToCellarNonPremium from './AddToCellarNonPremium';

const TRANSLATION_PATH = 'components.cellar.add_to_cellar';
const TRANSLATIONS = {
  addToCellar: `${TRANSLATION_PATH}.add_to_cellar`,
  bottlesInCellar: `${TRANSLATION_PATH}.bottles_in_cellar`,
};

export interface AddToCellarProps {
  vintage: RubyLibFullVintage;
  userVintage: FullUserVintage;
  averagePrice?: number;
  handleUpdateUserVintage: (userVintage: FullUserVintage) => void;
}

export default function AddToCellar({
  vintage,
  userVintage,
  averagePrice,
  handleUpdateUserVintage,
}: AddToCellarProps) {
  const bottleCount = userVintage?.cellar_count || 0;

  const {
    setDrawerState,
    drawerOpen,
    handleDrawerOpen,
    handleDrawerClose,
    handleCreateCellarEvent,
  } = useCellarContext();

  const { __PRELOADED_SIGNED_IN_USER__: signedInUser } = useAppContext();

  const isUserPremium = signedInUser?.is_premium;

  const { isMobile } = useBreakpoint();
  const { t } = useI18N();

  const drawerLocation = isMobile ? 'bottom' : 'right';

  useEffect(() => {
    setDrawerState({
      vintage,
      userVintage,
    });
  }, [userVintage]);

  function handleOnClick() {
    track({
      screen: 'Wine',
      event: 'Button - Add to Collection',
      props: {
        vivino_premium_member: true,
      },
    });
    handleDrawerOpen();
  }

  async function onCreateCellarEvent(difference: number, comment?: string) {
    await handleCreateCellarEvent(difference, comment);

    handleUpdateUserVintage({ ...userVintage, cellar_count: bottleCount + difference });
  }

  if (!isUserPremium) {
    return <AddToCellarNonPremium />;
  }

  return (
    <>
      <div className={styles.addToCellar}>
        {bottleCount > 0 && (
          <Button
            startIcon={<WineCellarIcon />}
            theme={ButtonTheme.PlainQuiet}
            onClick={handleOnClick}
          >
            {t(TRANSLATIONS.bottlesInCellar, { count: bottleCount })}
          </Button>
        )}

        {bottleCount === 0 && (
          <Button
            startIcon={<WineCellarIcon />}
            theme={ButtonTheme.PlainQuiet}
            onClick={handleOnClick}
          >
            {t(TRANSLATIONS.addToCellar)}
          </Button>
        )}
      </div>

      <Drawer anchor={drawerLocation} open={drawerOpen} onClose={handleDrawerClose}>
        <CellarDrawerContent
          vintageAveragePrice={averagePrice}
          handleCreateCellarEvent={onCreateCellarEvent}
          handleDrawerClose={handleDrawerClose}
        />
      </Drawer>
    </>
  );
}
