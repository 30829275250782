import React from 'react';

const VerticalFlagOnIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M18.75,21V4.8931A1.8423,1.8423,0,0,0,16.9752,3h-9.95A1.8423,1.8423,0,0,0,5.25,4.8931V21L12,15.6Z"
      fill="none"
      stroke="#1e1e1e"
      strokeLinecap="round"
      strokeLinejoin="round"
      fillRule="evenodd"
    />
    <path
      d="M16.8736,3H7.1264A1.985,1.985,0,0,0,5.2041,5.0384V20.8165a.1838.1838,0,0,0,.2985.1435L12,15.7619,18.4974,20.96a.1838.1838,0,0,0,.2985-.1435V5.0384A1.985,1.985,0,0,0,16.8736,3Z"
      fill="#1e1e1e"
    />
  </svg>
);

export default VerticalFlagOnIcon;
