import React from 'react';
import t from 'vivino-js/translationString';

import styles from './wineFacts.skeleton.scss';

const NUM_ROWS = 5;
const TRANSLATIONS = {
  facts: 'components.shared.wine_summary.facts',
};

const WineFactsSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t(TRANSLATIONS.facts)}</div>
      {Array(NUM_ROWS)
        .fill(0)
        .map((_, index) => (
          <div key={index} className={styles.row}>
            <div className={styles.icon}></div>
            <div className={styles.heading}></div>
            <div className={styles.content}></div>
          </div>
        ))}
    </div>
  );
};

export default WineFactsSkeleton;
