import { Badge, Button, ButtonTheme, WineCellarIcon } from '@vivino/js-react-common-ui';
import { isSignedIn, onLoginRequired, useI18N } from '@vivino/js-web-common';
import React from 'react';
import { track } from 'vivino-js/analytics';

import styles from './addToCellar.module.scss';

const TRANSLATION_PATH = 'components.cellar.add_to_cellar';
const TRANSLATIONS = {
  addToCellar: `${TRANSLATION_PATH}.add_to_cellar`,
  premium: `${TRANSLATION_PATH}.premium`,
};

export default function AddToCellarNonPremium() {
  const { t } = useI18N();

  return (
    <div
      className={styles.addToCellarNonPremium}
      onClick={() => {
        track({
          screen: 'Wine',
          event: 'Button - Add to Collection',
          props: {
            vivino_premium_member: false,
          },
        });

        if (!isSignedIn()) {
          onLoginRequired();
          return;
        }

        window.location.href = '/premium';
      }}
    >
      <Button startIcon={<WineCellarIcon />} theme={ButtonTheme.PlainQuiet}>
        {t(TRANSLATIONS.addToCellar)}
      </Button>

      <Badge label={t(TRANSLATIONS.premium)} variant="outlined" size="medium" />
    </div>
  );
}
