import { Anchor } from '@vivino/js-web-common';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './breadCrumbs.scss';

export const Separators = {
  Dot: 'Dot',
};

const SeparatorText = {
  [Separators.Dot]: '·',
};

const BreadCrumbs = ({ items, separator = Separators.Dot }) => {
  const separatorText = SeparatorText[separator];

  return (
    <div className={styles.breadCrumbs}>
      {items.map(({ url, name, ...restProps }, i) => (
        <span key={`breadcrumb-${i}`}>
          {i > 0 && <span className={styles.separator}>{separatorText}</span>}
          <Anchor href={url} className={styles.link} {...restProps}>
            {name}
          </Anchor>
        </span>
      ))}
    </div>
  );
};

BreadCrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
  separator: PropTypes.oneOf(Object.values(Separators)),
};

export default BreadCrumbs;
