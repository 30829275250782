import { Button, ButtonTheme } from '@vivino/js-react-common-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { trackAddToCart } from 'vivino-js/analytics';
import { createCart } from 'vivino-js/api/carts';
import { fetchPriceSubtotal } from 'vivino-js/api/prices';
import { price } from 'vivino-js/apiPropTypes';
import { formSize } from 'vivino-js/commonPropTypes';
import { CartItemSourceContext } from 'vivino-js/context/CartItemSourceContext';
import { MerchandizingCampaignContext } from 'vivino-js/context/MerchandizingContext';
import { getCartItemSource, sanitiseCartItemSource } from 'vivino-js/helpers/cartItemSources';
import { formatNumber } from 'vivino-js/helpers/price';
import t from 'vivino-js/translationString';
import { cartUrl } from 'vivino-js/vivinoUrls';

import styles from './addToCartButton.scss';

const TRANSLATIONS = {
  addToCart: 'components.shared.add_to_cart_button.add_to_cart',
};

const AddToCartButton = ({
  price,
  className,
  disabled,
  seenVintageId,
  hidePrice,
  bottleCount,
  onError,
  size,
  dataTestId,
  fullWidth,
}) => {
  const [inProgress, setInProgress] = useState(false);
  const cartItemSourceType = useContext(CartItemSourceContext);
  const campaignId = useContext(MerchandizingCampaignContext);

  if (!price || !price.currency) {
    return null;
  }

  const source = sanitiseCartItemSource(getCartItemSource(cartItemSourceType, campaignId));

  const renderButtonContent = () =>
    hidePrice ? (
      t(TRANSLATIONS.addToCart)
    ) : (
      <div className={styles.price}>
        {price.currency.prefix && (
          <div className={cx(styles.currency, styles.prefix)}>{price.currency.prefix}</div>
        )}
        <div>{formatNumber(price.amount)}</div>
        {price.currency.suffix && (
          <div className={cx(styles.currency, styles.suffix)}>{price.currency.suffix}</div>
        )}
      </div>
    );

  const trackAndRedirect = (cartId, bottleCount) => {
    trackAddToCart({
      currencyCode: price.currency.code,
      quantity: bottleCount,
      priceAmount: price.amount,
      priceType: price.type,
      vintageId: seenVintageId,
      source,
    });

    window.location.assign(cartUrl({ cartId }));
  };

  const addToCartAfterFetchSubtotal = async () => {
    try {
      const { bottle_count } = await fetchPriceSubtotal({ priceId: price.id });

      const cartData = await createCart({
        bottleCount: bottle_count,
        priceId: price.id,
        seenVintageId,
        source,
      });

      trackAndRedirect(cartData.cart.id, bottle_count);
      setInProgress(false);
    } catch (error) {
      setInProgress(false);
      onError ? onError(error.message) : window.alert(error.message);
    }
  };

  const handleClick = async (event) => {
    event.preventDefault();
    setInProgress(true);

    if (bottleCount) {
      try {
        const cartData = await createCart({
          bottleCount,
          priceId: price.id,
          seenVintageId,
          source,
        });

        trackAndRedirect(cartData.cart.id, bottleCount);
        setInProgress(false);
      } catch (e) {
        addToCartAfterFetchSubtotal();
      }
    } else {
      // if bottleCount is not passed as props
      // need to fetch subtotal to get bottleCount before adding to cart
      addToCartAfterFetchSubtotal();
    }
  };

  return (
    <Button
      className={className}
      disabled={disabled}
      working={inProgress}
      onClick={handleClick}
      aria-label={t(TRANSLATIONS.addToCart)}
      size={size}
      dataTestId={dataTestId || 'addToCart'}
      fullWidth={fullWidth}
      theme={ButtonTheme.Accent}
    >
      {renderButtonContent()}
    </Button>
  );
};

AddToCartButton.propTypes = {
  bottleCount: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hidePrice: PropTypes.bool,
  fullWidth: PropTypes.bool,
  price: price,
  seenVintageId: PropTypes.number.isRequired,
  showCartIcon: PropTypes.bool,
  onError: PropTypes.func,
  size: formSize,
  dataTestId: PropTypes.string,
};

export default AddToCartButton;
