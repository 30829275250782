import Rating from '@components/Rating/Rating';
import Star from '@components/Star/Star';
import { Typography, TypographyType } from '@vivino/js-react-common-ui';
import {
  RubyLibFullVintage,
  VivinoRatingType,
  getRatingForVintage,
  showRatingFor,
  useI18N,
} from '@vivino/js-web-common';
import React from 'react';

import styles from './averageRating.module.scss';
import { formatNumber } from '@lib/numbers';

const TRANSLATIONS_PATH = 'components.shared.vivino_rating';
const TRANSLATIONS = {
  notRatedYet: `${TRANSLATIONS_PATH}.not_rated_yet`,
  notEnoughRatings: `${TRANSLATIONS_PATH}.not_enough_ratings`,
  basedOnAllVintages: `${TRANSLATIONS_PATH}.based_on_all_vintages`,
};

interface AverageRatingProps {
  vintage: RubyLibFullVintage;
}

export default function AverageRating({ vintage }: AverageRatingProps) {
  const { t } = useI18N();

  const rating = getRatingForVintage({ vintage });
  const [ratingType, ratingVintage] = showRatingFor({ vintage });
  const numberOfRatings = ratingVintage.statistics.ratings_count;

  const ratingText =
    ratingType === VivinoRatingType.Vintage
      ? formatNumber({ amount: numberOfRatings })
      : t(TRANSLATIONS.basedOnAllVintages);

  const noRatings = numberOfRatings === 0;
  const notEnoughRatings = numberOfRatings < 25;

  if (noRatings) {
    return (
      <div className={styles.averageRating}>
        <Star rating={0} />
        <Typography type={TypographyType.BodyMedium} className={styles.helpText}>
          {t(TRANSLATIONS.notRatedYet)}
        </Typography>
      </div>
    );
  }

  if (notEnoughRatings) {
    return (
      <div className={styles.averageRating}>
        <Star rating={0} />
        <Typography type={TypographyType.BodyMedium} className={styles.helpText}>
          {t(TRANSLATIONS.notEnoughRatings)}
        </Typography>
      </div>
    );
  }

  return (
    <div className={styles.averageRating}>
      <Star rating={rating} />
      <Rating rating={rating} size="large" />
      <Typography type={TypographyType.BodyMedium} className={styles.numberOfRatings}>
        ({ratingText})
      </Typography>
    </div>
  );
}
