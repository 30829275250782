import SnackBarProvider from '@components/SnackBarProvider/SnackBarProvider';
import { ThemeProvider } from '@vivino/js-react-common-ui';
import {
  AppContextProps,
  AppProvider,
  ExperimentAttributesProvider,
  I18NProvider,
} from '@vivino/js-web-common';
import React, { ReactNode } from 'react';
import { ErrorBoundary } from 'vivino-js/utilities/ErrorBoundary';

interface PageProps {
  children: ReactNode;
  latest?: boolean;
}

const windowToAppContext = (): AppContextProps => {
  return {
    __PRELOADED_ENV__: window.__PRELOADED_ENV__,
    __PRELOADED_COUNTRY_CODE__: window.__PRELOADED_COUNTRY_CODE__,
    __PRELOADED_STATE_CODE__: window.__PRELOADED_STATE_CODE__,
    __PRELOADED_LOCALE__: window.__PRELOADED_LOCALE__,
    __PRELOADED_SIGNED_IN_USER__: window.__PRELOADED_SIGNED_IN_USER__,
    __PRELOADED_STATE__: window.__PRELOADED_STATE__,
    __PRELOADED_SPLIT_CLIENT_KEY__: window.__PRELOADED_SPLIT_CLIENT_KEY__,
    __PRELOADED_USER_ACCESSIBILITY_ENABLED__: window.__PRELOADED_USER_ACCESSIBILITY_ENABLED__,
    __PRELOADED_CAMPAIGN_USER_ID__: window.__PRELOADED_CAMPAIGN_USER_ID__,
    __PRELOADED_ANONYMOUS_TRACKING_ID__: window.__PRELOADED_ANONYMOUS_TRACKING_ID__,
    __PRELOADED_AB_TESTS__: window.__PRELOADED_AB_TESTS__,
    __PRELOADED_SMOKE_TEST__: window.__PRELOADED_SMOKE_TEST__,
    __PRELOADED_TRACKING_SCREEN_NAME__: window.__PRELOADED_TRACKING_SCREEN_NAME__,
    __PRELOADED_IS_BOT_REQUEST__: window.__PRELOADED_IS_BOT_REQUEST__,
    __VIVINO_GLOBALS__: window.__VIVINO_GLOBALS__,
    __PRELOADED_USER_NOTIFICATION__: window.__PRELOADED_USER_NOTIFICATION__,
    __PRELOADED_SHIPPING_ZIP_CODE__: window.__PRELOADED_SHIPPING_ZIP_CODE__,
    __PRELOADED_SHIPPING_ZIP_PREFILL__: window.__PRELOADED_SHIPPING_ZIP_PREFILL__,
    __WEB_API_HOST__: window.__WEB_API_HOST__,
    vivinoCacheKey: window.vivinoCacheKey,
    locale: window.locale,
    menuData: window.menuData,
    userCellars: window.__PRELOADED_USER_CELLARS__,
  };
};

/*
 * The Page component can be used for adding providers
 * that needs to be available for all components.
 * For instance it adds the ThemeProvider, which allows us to define the theme for Common UI, which
 * is necessary to get the correct look of components consumed through the Common UI.
 * It should be the top most component of any ReactDOM.render.
 */
const Page = ({ children }: PageProps) => {
  return (
    <ErrorBoundary>
      <AppProvider value={windowToAppContext()}>
        <I18NProvider localePack={window.localePack}>
          <ThemeProvider>
            <SnackBarProvider>
              <ExperimentAttributesProvider>{children}</ExperimentAttributesProvider>
            </SnackBarProvider>
          </ThemeProvider>
        </I18NProvider>
      </AppProvider>
    </ErrorBoundary>
  );
};

export default Page;
