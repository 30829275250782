import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './pageSection.scss';

const PageSection = ({ children, className }) => {
  const onlyChild = React.Children.only(children);

  return <div className={cx(className, styles.pageSection)}>{onlyChild}</div>;
};

PageSection.propTypes = {
  className: PropTypes.string,
  noBorder: PropTypes.bool,
  children: PropTypes.node,
};

export default PageSection;
