import { useBreakpoint } from '@vivino/js-react-common-ui';
import { ActionButton } from '@vivino/js-web-common';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { track } from 'vivino-js/analytics';
import { updateReview } from 'vivino-js/api/reviews';
import { isCurrentUser } from 'vivino-js/helpers/user';
import PopupContainer, {
  PopupHorizontalAlign,
  PopupVerticalAlign,
} from 'vivino-ui/atoms/PopupContainer';
import CommunityReviewsTrackingContext from 'vivino-ui/components/CommunityReviews/CommunityReviewsTrackingContext';
import MoreIcon from 'vivino-ui/icons/More';

import RatingReviewEditor from '../RatingReviewEditor';
import styles from './moreActionsButton.scss';
import MoreActionsMenu from './MoreActionsMenu.presentational';

const MoreActionsButton = ({ review, onReviewUpdated, onReviewDeleted, className }) => {
  const trackedFrom = useContext(CommunityReviewsTrackingContext);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isReviewEditorVisible, setIsReviewEditorVisible] = useState(false);
  const { isMobile } = useBreakpoint();

  if (!isCurrentUser(review.user)) {
    return null;
  }

  const onOpen = () => {
    setIsShowPopup(true);

    track({
      event: 'User review - more actions',
      props: { trackedFrom },
    });
  };

  const onClose = () => {
    setIsShowPopup(false);
  };

  const openReviewEditor = () => {
    setIsReviewEditorVisible(true);

    track({
      event: 'User review - edit review open',
      props: { trackedFrom },
    });
  };

  const submitUpdatedRatingAndReview = async ({ rating, note }) => {
    const data = await updateReview({
      reviewId: review.id,
      rating,
      note,
    });
    setIsReviewEditorVisible(false);
    track({
      event: 'User review - edit review submit',
      props: { trackedFrom },
    });
    onReviewUpdated(data.review);
  };

  const popup = (
    <MoreActionsMenu
      review={review}
      onOpenReviewEditor={openReviewEditor}
      onReviewDeleted={onReviewDeleted}
      onMenuItemSelected={onClose}
    />
  );

  return (
    <div className={className}>
      <PopupContainer
        popup={popup}
        isShowPopup={isShowPopup}
        onOpen={onOpen}
        onClose={onClose}
        verticalAlign={PopupVerticalAlign.TOP}
        horizontalAlign={isMobile ? PopupHorizontalAlign.RIGHT : PopupHorizontalAlign.CENTER}
      >
        <ActionButton className={styles.moreActionsButton} ariaLabel="More actions">
          <MoreIcon className={styles.icon} />
        </ActionButton>
      </PopupContainer>
      {isReviewEditorVisible && (
        <RatingReviewEditor
          vintage={review.vintage}
          initialRating={review.rating}
          initialNote={review.note}
          show={isReviewEditorVisible}
          onClose={() => setIsReviewEditorVisible(false)}
          onSubmit={submitUpdatedRatingAndReview}
        />
      )}
    </div>
  );
};

MoreActionsButton.propTypes = {
  review: PropTypes.object.isRequired,
  onReviewUpdated: PropTypes.func.isRequired,
  onReviewDeleted: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default MoreActionsButton;
