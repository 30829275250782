import { Typography, TypographyType } from '@vivino/js-react-common-ui';
import { formatRating } from '@vivino/js-web-common';
import React from 'react';

import styles from './rating.module.scss';

interface RatingProps {
  rating: number;
  size?: 'small' | 'large';
}

export default function Rating({ rating, size = 'small' }: RatingProps) {
  return (
    <div className={styles.rating}>
      <Typography
        type={size === 'small' ? TypographyType.LabelLarge : TypographyType.TitleExtraLarge}
        className={styles.number}
      >
        {formatRating(rating)}
      </Typography>
    </div>
  );
}
