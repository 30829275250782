import { TextArea } from '@vivino/js-react-common-ui';
import { onLoginRequired, UserAvatar } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { track } from 'vivino-js/analytics';
import { createComment, deleteComment, fetchComments, updateComment } from 'vivino-js/api/comments';
import { CommonPropTypes, ComponentSizes } from 'vivino-js/commonPropTypes';
import { getCurrentUser, isSignedIn } from 'vivino-js/helpers/user';
import t from 'vivino-js/translationString';
import CommunityReviewsTrackingContext from 'vivino-ui/components/CommunityReviews/CommunityReviewsTrackingContext';

import { FETCH_COMMENTS_COUNT, LOAD_MORE_COMMENTS_COUNT } from './commentsConstants';
import commentsReducer, { CommentsActionTypes } from './commentsReducer';
import styles from './commentsSection.scss';
import Comment from './components/Comment';

const LOAD_ANIMATION_DURATION = 50;
const COMMENTS_ANIMATION_DURATION = 150;
const NEW_COMMENT_ANIMATION_DURATION = 50;

const TRANSLATIONS_PATH = 'components.shared.comments_section';
const TRANSLATIONS = {
  loadPreviousComments: `${TRANSLATIONS_PATH}.load_previous_comments`,
  leaveAComment: `${TRANSLATIONS_PATH}.leave_a_comment`,
};

const CommentsSection = ({
  activityId,
  size = ComponentSizes.Base,
  isCommentsVisible = false,
  commentsCount = 0,
  onUpdateCommentsCount,
}) => {
  const trackedFrom = useContext(CommunityReviewsTrackingContext);
  const [isInitialized, setIsInitialized] = useState(false);
  const [state, dispatch] = useReducer(commentsReducer, {
    fetchedComments: [],
    visibleCommentsCount: 0,
  });
  const [commentInput, setCommentInput] = useState('');

  useEffect(() => {
    if (isCommentsVisible && !isInitialized) {
      fetchComments({
        activityId,
        page: 1,
        perPage: FETCH_COMMENTS_COUNT,
      }).then((data) => {
        dispatch({ type: CommentsActionTypes.INIT, comments: data.comments });
      });
      setIsInitialized(true);
    }
  }, [activityId, isCommentsVisible]);

  const handleLoadMoreComments = () => {
    const newCount = state.visibleCommentsCount + LOAD_MORE_COMMENTS_COUNT;
    const fetchedCount = state.fetchedComments.length;
    if (newCount > fetchedCount && commentsCount > fetchedCount) {
      fetchComments({
        activityId,
        page: Math.ceil(newCount / FETCH_COMMENTS_COUNT),
        perPage: FETCH_COMMENTS_COUNT,
      }).then((data) => {
        dispatch({
          type: CommentsActionTypes.FETCH_MORE,
          count: newCount,
          comments: data.comments,
        });
      });
    } else {
      dispatch({ type: CommentsActionTypes.RENDER_MORE, count: newCount });
    }

    track({
      event: 'User review - load more comments',
      props: { trackedFrom },
    });
  };

  const handleCommentInputChange = (e) => {
    setCommentInput(e.currentTarget.value);
  };

  const handleCommentOnClick = (e) => {
    if (isSignedIn()) {
      return;
    }
    onLoginRequired();
    e.target.blur();
  };

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      handleAddComment();
    }
  };

  const handleAddComment = async () => {
    if (!commentInput.trim()) {
      return;
    }

    const { comment } = await createComment({
      activityId,
      comment: commentInput,
    });

    dispatch({ type: CommentsActionTypes.ADD, comment });
    onUpdateCommentsCount(1); // increment commentsCount
    setCommentInput('');

    track({
      event: 'User review - add comment',
      props: { trackedFrom },
    });
  };

  const handleUpdateComment = async ({ id: commentId, commentInput }) => {
    const { comment } = await updateComment({
      activityId,
      commentId,
      comment: commentInput,
    });

    dispatch({ type: CommentsActionTypes.UPDATE, comment });

    track({
      event: 'User review - update comment',
      props: { trackedFrom },
    });
  };

  const handleDeleteComment = async (commentId) => {
    await deleteComment({
      activityId,
      commentId,
    });

    dispatch({ type: CommentsActionTypes.DELETE, commentId });
    onUpdateCommentsCount(-1); // decrement commentsCount

    track({
      event: 'User review - delete comment',
      props: { trackedFrom },
    });
  };

  // Somehow, review.activity.statistics.comments_count doesn't match the number of actual comments
  // on testing. So don't rely on that value completely, and use fetchedComments.length when we can
  const commentsCountToRender =
    state.fetchedComments.length % FETCH_COMMENTS_COUNT > 0
      ? state.fetchedComments.length
      : commentsCount;
  const isLoadPreviousVisible =
    isCommentsVisible &&
    state.visibleCommentsCount > 0 &&
    commentsCountToRender > state.visibleCommentsCount;

  return (
    <div className={styles.commentsSection}>
      <div
        className={cx(styles.comments, {
          [styles.visible]: isCommentsVisible,
        })}
      >
        <AnimateHeight
          duration={NEW_COMMENT_ANIMATION_DURATION}
          height={isCommentsVisible ? 'auto' : 0}
          easing="ease-in"
        >
          <div className={styles.commentGroupContainer}>
            <div className={styles.newComment}>
              <UserAvatar user={getCurrentUser()} className={styles.avatar} />
              <TextArea
                fullWidth
                name="comment"
                placeholder={t(TRANSLATIONS.leaveAComment)}
                onChange={handleCommentInputChange}
                onKeyDown={handleOnKeyDown}
                onClick={handleCommentOnClick}
                value={commentInput}
                maxLength={512}
                className={styles.textArea}
              />
            </div>
          </div>
        </AnimateHeight>
        <AnimateHeight
          duration={COMMENTS_ANIMATION_DURATION}
          height={isCommentsVisible && state.visibleCommentsCount > 0 ? 'auto' : 0}
          easing="ease-in"
        >
          {state.fetchedComments.slice(0, state.visibleCommentsCount).map((comment) => (
            <div key={comment.id} className={styles.commentGroupContainer}>
              <Comment
                comment={comment}
                size={size}
                onUpdateComment={handleUpdateComment}
                onDeleteComment={handleDeleteComment}
              />
            </div>
          ))}
        </AnimateHeight>
        <AnimateHeight
          duration={LOAD_ANIMATION_DURATION}
          height={isLoadPreviousVisible ? 'auto' : 0}
          easing="ease-in"
        >
          <div onClick={handleLoadMoreComments} className={styles.loadPrevious}>
            {t(TRANSLATIONS.loadPreviousComments)}
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
};

CommentsSection.propTypes = {
  activityId: PropTypes.number,
  size: CommonPropTypes.componentSize,
  isCommentsVisible: PropTypes.bool,
  commentsCount: PropTypes.number,
  onUpdateCommentsCount: PropTypes.func, // to notify comment action button to update count
};

export default CommentsSection;
