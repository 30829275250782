import { Button, ButtonTheme, useBreakpoint } from '@vivino/js-react-common-ui';
import { captureException, onLoginRequired } from '@vivino/js-web-common';
import { BasicWishlistUserVintage } from 'app/javascript/types/goApi';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { addToWishlist, removeFromWishlist } from 'vivino-js/api/wishlist';
import { isSignedIn } from 'vivino-js/helpers/user';
import t from 'vivino-js/translationString';
import { VerticalFlagOffIcon16, VerticalFlagOnIcon16 } from 'vivino-ui/icons/VerticalFlagIcons/16';
import { VerticalFlagOffIcon24, VerticalFlagOnIcon24 } from 'vivino-ui/icons/VerticalFlagIcons/24';

const TRANSLATIONS = {
  removeFromWishlist: 'components.shared.add_to_wishlist.wishlisted',
  addToWishlist: 'components.shared.add_to_wishlist.add_to_wishlist',
  wineAddedToWishlist: 'components.shared.add_to_wishlist.wine_added_to_wishlist',
};

interface AddToWishlistProps {
  userVintage?: BasicWishlistUserVintage;
  vintageId: number;
}

const AddToWishlist = ({ vintageId, userVintage }: AddToWishlistProps) => {
  const [isWishListed, setIsWishListed] = useState(!!userVintage?.wishlisted_at);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { isMobile } = useBreakpoint();

  function handleWishListClick(e) {
    e.preventDefault(); // to prevent scroll-to-top

    if (!isSignedIn()) {
      onLoginRequired();
      return;
    }

    if (isWishListed) {
      removeFromWishlist({ vintageId })
        .then(() => {
          setIsWishListed(false);
        })
        .catch((error) => {
          captureException(error);
          enqueueSnackbar(error.message, {
            variant: 'error',
            onClose: () => closeSnackbar(),
          });
        });
    } else {
      addToWishlist({ vintageId })
        .then(() => {
          setIsWishListed(true);
          enqueueSnackbar(t(TRANSLATIONS.wineAddedToWishlist), {
            variant: 'success',
            onClose: () => closeSnackbar(),
          });
        })
        .catch((error) => {
          captureException(error);
          enqueueSnackbar(error.message, {
            variant: 'error',
            onClose: () => closeSnackbar(),
          });
        });
    }
  }

  const VerticalFlagOnIcon = () => (isMobile ? <VerticalFlagOnIcon16 /> : <VerticalFlagOnIcon24 />);

  const VerticalFlagOffIcon = () =>
    isMobile ? <VerticalFlagOffIcon16 /> : <VerticalFlagOffIcon24 />;

  return isWishListed ? (
    <Button
      startIcon={<VerticalFlagOnIcon />}
      theme={ButtonTheme.PlainQuiet}
      onClick={handleWishListClick}
    >
      {t(TRANSLATIONS.removeFromWishlist)}
    </Button>
  ) : (
    <Button
      startIcon={<VerticalFlagOffIcon />}
      theme={ButtonTheme.PlainQuiet}
      onClick={handleWishListClick}
    >
      {t(TRANSLATIONS.addToWishlist)}
    </Button>
  );
};

export default AddToWishlist;
