import CellarUserData from '@components/CellarListCard/CellarUserData/CellarUserData';
import DrinkingWindow from '@components/DrinkingWindow/DrinkingWindow';
import VintageCoreData from '@components/VintageCoreData/VintageCoreData';
import { Button, ButtonTheme, CrossIcon } from '@vivino/js-react-common-ui';
import { Currency, ErrorBoundary } from '@vivino/js-web-common';
import React from 'react';

import CellarBottleQuantity from '../CellarBottleQuantity/CellarBottleQuantity';
import { useCellarContext } from '../CellarContext/CellarContext';
import styles from './cellarDrawerContent.module.scss';

interface CellarDrawerContentProps {
  currency?: Currency;
  vintageAveragePrice?: number;
  handleCreateCellarEvent: (difference: number, comment?: string) => void;
  handleDrawerClose?: () => void;
}

export default function CellarDrawerContent({
  currency,
  vintageAveragePrice,
  handleCreateCellarEvent,
  handleDrawerClose,
}: CellarDrawerContentProps) {
  const { drawerState, cellarName } = useCellarContext();
  const { vintage, userVintage } = drawerState;
  const showCloseButton = !!handleDrawerClose;

  return (
    <ErrorBoundary>
      <div className={styles.cellarDrawerContent} data-testid="cellar-drawer-content">
        <>
          {showCloseButton && (
            <div className={styles.cellarDrawerContentHeader}>
              <Button iconButton theme={ButtonTheme.Plain}>
                <CrossIcon onClick={handleDrawerClose} />
              </Button>
            </div>
          )}

          {vintage && (
            <VintageCoreData
              compact
              vintage={vintage}
              userVintage={userVintage}
              averagePrice={vintageAveragePrice || null}
              averagePriceCurrency={currency || null}
            />
          )}

          <CellarBottleQuantity
            cellarName={cellarName}
            userVintage={userVintage}
            handleCreateCellarEvent={handleCreateCellarEvent}
          />

          <DrinkingWindow
            recommendedDrinkingWindow={vintage?.recommended_drinking_window}
            userDrinkingWindow={userVintage?.drinking_window}
          />

          {userVintage && <CellarUserData userVintage={userVintage} />}
        </>
      </div>
    </ErrorBoundary>
  );
}
