import { Alert, AlertTheme } from '@vivino/js-react-common-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { price } from 'vivino-js/apiPropTypes';
import { isPPCPrice, priceToString } from 'vivino-js/helpers/price';
import t from 'vivino-js/translationString';
import Price from 'vivino-ui/atoms/Price';
import AddToCartButton from 'vivino-ui/components/AddToCartButton';

import styles from './addToCart.scss';

const TRANSLATIONS_PATH = 'components.shared.add_to_cart_button';
const TRANSLATIONS = {
  soldOut: `${TRANSLATIONS_PATH}.sold_out`,
  availableOnline: `${TRANSLATIONS_PATH}.available_online`,
  priceForTheYear: `${TRANSLATIONS_PATH}.price_for_the_year`,
};

const AddToCart = ({
  price,
  seenVintageId,
  vintageYear,
  showOutOfStock = true,
  className,
  fullWidth = false,
}) => {
  const [warningMessage, setWarningMessage] = useState('');

  const handleError = (message) => {
    setWarningMessage(message);
  };

  if (!price) {
    return showOutOfStock ? (
      <div className={cx(styles.subText, styles.soldOut)}>{t(TRANSLATIONS.soldOut)}</div>
    ) : null;
  }

  if (isPPCPrice(price)) {
    return (
      <div className={cx(styles.subText, styles.ppcPrice)}>
        {t(TRANSLATIONS.availableOnline, {
          price: priceToString({
            amount: price.amount,
            currency: price.currency,
          }),
        })}
      </div>
    );
  }

  return (
    <>
      <AddToCartButton
        price={price}
        seenVintageId={seenVintageId}
        onError={handleError}
        disabled={!!warningMessage}
        className={className}
        fullWidth={fullWidth}
      />
      {warningMessage && (
        <Alert subdued theme={AlertTheme.Critical} className={styles.warningMessage}>
          {warningMessage}
        </Alert>
      )}
      {!!price.discounted_from && (
        <Price
          strike
          amount={price.discounted_from}
          currency={price.currency}
          className={styles.subText}
        />
      )}
      {price.message && <div className={styles.subText}>{price.message}</div>}
      {vintageYear && (
        <div className={styles.subText}>
          {t(TRANSLATIONS.priceForTheYear, { year: vintageYear })}
        </div>
      )}
    </>
  );
};

AddToCart.propTypes = {
  price: price,
  seenVintageId: PropTypes.number.isRequired,
  vintageYear: PropTypes.number,
  showOutOfStock: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
};

export default AddToCart;
