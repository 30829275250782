import { Currency } from '@vivino/js-web-common';
import { apiGetJsWeb } from 'vivino-js/apiClientJsWeb';

interface GetCurrenciesReturnType {
  currencies: Record<string, Currency>;
}

export const getCurrencies = () =>
  apiGetJsWeb<GetCurrenciesReturnType>({
    url: '/currencies',
  });
