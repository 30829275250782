import { Typography, TypographyType } from '@vivino/js-react-common-ui';
import { ExtendedPrice, priceToString, useI18N } from '@vivino/js-web-common';
import React from 'react';

const TRANSLATIONS_PATH = 'components.shared.user_vintage_price';
const TRANSLATIONS = {
  atLocation: `${TRANSLATIONS_PATH}.at_location`,
};

interface UserVintagePriceProps {
  price: ExtendedPrice;
}

export default function UserVintagePrice({ price }: UserVintagePriceProps) {
  const { t } = useI18N();

  const amount = price.amount / (price.bottle_quantity || 1);
  const currency = price.currency;

  const bottleQuantity = price.bottle_quantity || 1;

  const localizedBottleType = t(`bottle_types.${price.bottle_type_id}`);

  const locationName = price?.location?.name;

  return (
    <Typography type={TypographyType.BodyMedium}>
      {priceToString({ amount, currency })} · {bottleQuantity} {localizedBottleType}
      {locationName && ` ${t(TRANSLATIONS.atLocation, { location: locationName })}`}
    </Typography>
  );
}
