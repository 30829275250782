import { Button, useBreakpoint } from '@vivino/js-react-common-ui';
import cx from 'classnames';
import React, { useContext } from 'react';
import { CartItemSourceContext } from 'vivino-js/context/CartItemSourceContext';
import { MerchandizingCampaignContext } from 'vivino-js/context/MerchandizingContext/MerchandizingContext';
import { getCartItemSource, getCartItemSourceProps } from 'vivino-js/helpers/cartItemSources';
import t from 'vivino-js/translationString';

import styles from './showAllButton.scss';

const TRANSLATIONS = {
  showAll: 'common.show_all',
};

interface ShowAllButtonProps {
  url: string;
  totalCount?: number;
  center?: boolean;
}

const ShowAllButton = ({ url, totalCount = 0, center = false }: ShowAllButtonProps) => {
  const { isMobile } = useBreakpoint();
  const cartItemSourceType = useContext(CartItemSourceContext);
  const campaignId = useContext(MerchandizingCampaignContext);
  const cartItemSourceParam = getCartItemSource(cartItemSourceType, campaignId);

  if (!url) {
    return null;
  }

  return (
    url &&
    totalCount > 0 && (
      <div
        className={cx(styles.buttonWrapper, { [styles.center]: center })}
        data-testid="showAllButton"
      >
        <Button href={url} {...getCartItemSourceProps(cartItemSourceParam)} fullWidth={isMobile}>
          {t(TRANSLATIONS.showAll)}
        </Button>
      </div>
    )
  );
};

export default ShowAllButton;
