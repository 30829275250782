import React from 'react';

const VerticalFlagOffIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path
      d="M12.5,14V3.2621A1.2281,1.2281,0,0,0,11.3168,2H4.6832A1.2281,1.2281,0,0,0,3.5,3.2621V14L8,10.4Z"
      fill="none"
      stroke="#1e1e1e"
      strokeLinecap="round"
      strokeLinejoin="round"
      fillRule="evenodd"
    />
  </svg>
);

export default VerticalFlagOffIcon;
