import { BasicCountry, PremiumPlan } from '@vivino/js-web-common';
import React, { ReactNode, createContext, useContext, useEffect, useMemo } from 'react';
import { trackEvent } from 'vivino-js/analytics';
import { UserAction } from '../common/CTAButton/CTAButton';

const VIVINO_PREMIUM = 'Vivino Premium';

export enum PremiumPage {
  LANDING_PAGE = 'Landing page',
  GIFT_PAGE = 'Gift page',
  GIFT_CHECKOUT = 'Gift checkout',
  CODE_REDEEM = 'Code Redemption',
  CONFIRMATION = 'Confirmation',
  PREMIUM_SIGNUP = 'Plan Selector',
  GIFT_CONFIRMATION = 'Gift Confirmation',
  CODE_CONFIRMATION = 'Code Confirmation',
}

export enum PageEvent {
  COUNTRY_ERROR = 'Country error',
  SHOW = 'Show',
  ERROR_SHOW = 'Error show',
}

export enum AppAction {
  APP_STORE = 'Appstore',
  PLAY_STORE = 'Playstore',
}

export interface PremiumSubscriptionPlan extends PremiumPlan {
  price: { amount: number; currency_code: string };
  name: string;
}

export interface PremiumGiftCard {
  amount: number;
  currency_code: string;
  name: string;
  stripe_price_id: string;
}

export type Platform = 'iphone' | 'android' | 'web' | 'internal' | 'coupon';

export interface PremiumSubscription {
  id: string;
  plan_id: number;
  platform: Platform;
  external_id: string;
  status: 'pending' | 'active' | 'cancelled' | 'revoked' | 'expired';
  type: 'unknown' | 'paid' | 'free' | 'test';
  is_renewable: boolean;
  valid_until: string; // date string
  activated_at: string; // date string
}
interface PremiumContextReturnType {
  trackAction: (action: UserAction, restProps?: object) => void;
  trackCustomAction: (action: UserAction, restProps?: object) => void;
  trackDownloadApp: (action: AppAction) => void;
  trackPageEvent: (event: PageEvent) => void;
  getCountryName: (code: string) => string;
  appLinks: { APPLE_APPSTORE_URL: string; GOOGLE_PLAY_URL: string };
  giftCards?: PremiumGiftCard[];
  user?: SimpleUser;
  availablePlans: PremiumSubscriptionPlan[];
  page: PremiumPage;
  subscriptions: PremiumSubscription[];
}

type SimpleUser = { name: string; email: string };

function createInitialContextValue(): PremiumContextReturnType {
  return {
    trackAction: () => {},
    trackCustomAction: () => {},
    trackDownloadApp: () => {},
    trackPageEvent: () => {},
    getCountryName: () => '',
    appLinks: { APPLE_APPSTORE_URL: '', GOOGLE_PLAY_URL: '' },
    giftCards: [],
    availablePlans: [],
    page: PremiumPage.LANDING_PAGE,
    subscriptions: [],
  };
}

export const PremiumContext = createContext<PremiumContextReturnType>(createInitialContextValue());

const usePremiumContext = () => useContext(PremiumContext);

const PremiumProvider = ({
  page,
  children,
  countries = [],
  appLinks = { APPLE_APPSTORE_URL: '', GOOGLE_PLAY_URL: '' },
  giftCards = [],
  availablePlans = [],
  subscriptions = [],
}: {
  page: PremiumPage;
  children: ReactNode;
  countries?: BasicCountry[];
  appLinks?: { APPLE_APPSTORE_URL: string; GOOGLE_PLAY_URL: string };
  giftCards?: PremiumGiftCard[];
  availablePlans?: PremiumSubscriptionPlan[];
  subscriptions?: PremiumSubscription[];
}) => {
  useEffect(() => {
    trackPageEvent(PageEvent.SHOW);
  }, []);

  const trackAction = (action, restProps = {}) => {
    trackEvent({
      event: `${VIVINO_PREMIUM} - ${page} - Action`,
      props: { action, ...restProps },
    });
  };

  const trackCustomAction = (action, restProps = {}) => {
    trackEvent({
      event: `${VIVINO_PREMIUM} - ${page} - ${action}`,
      props: { action, ...restProps },
    });
  };

  const trackDownloadApp = (action) => {
    trackEvent({
      event: `${VIVINO_PREMIUM} - Download - Action`,
      props: { action },
    });
  };

  const trackPageEvent = (event) => {
    trackEvent({
      event: `${VIVINO_PREMIUM} - ${page} - ${event}`,
    });
  };

  const getCountryName = (code) => {
    const country = countries.find((country) => country.code === code);
    return country?.name || '';
  };

  const contextValue = useMemo(
    () => ({
      trackAction,
      trackCustomAction,
      trackDownloadApp,
      trackPageEvent,
      getCountryName,
      appLinks,
      giftCards,
      availablePlans,
      page,
      subscriptions,
    }),
    [page, countries, appLinks]
  );

  return <PremiumContext.Provider value={contextValue}>{children}</PremiumContext.Provider>;
};

export { PremiumProvider, usePremiumContext, UserAction };
