import React from 'react';

import { Typography, TypographyType } from '@vivino/js-react-common-ui';
import { VintageDrinkingWindow, useI18N } from '@vivino/js-web-common';

import {
  DrinkingWindowStatus,
  drinkingWindowStatusMap,
} from '@lib/drinking_window/drinking_window';

import DrinkingWindowIcon from './DrinkingWindowIcon/DrinkingWindowIcon';

import styles from './drinkingWindow.module.scss';

const TRANSLATIONS_PATH = 'components.drinking_window';
const TRANSLATIONS = {
  drinkAtYourPace: `${TRANSLATIONS_PATH}.drink_at_your_pace`,
  hold: `${TRANSLATIONS_PATH}.hold`,
  drinkOrHold: `${TRANSLATIONS_PATH}.drink_or_hold`,
  drinkNow: `${TRANSLATIONS_PATH}.drink_now`,
  pastItsPeak: `${TRANSLATIONS_PATH}.past_its_peak`,
};

interface DrinkingWindowProps {
  recommendedDrinkingWindow?: VintageDrinkingWindow;
  userDrinkingWindow?: VintageDrinkingWindow;
}

export default function DrinkingWindow({
  userDrinkingWindow,
  recommendedDrinkingWindow,
}: DrinkingWindowProps) {
  const { t } = useI18N();

  const drinkingWindow = userDrinkingWindow || recommendedDrinkingWindow;

  if (!drinkingWindow) {
    return null;
  }

  const status: DrinkingWindowStatus = drinkingWindowStatusMap[drinkingWindow.status];

  const textMap = {
    [DrinkingWindowStatus.DrinkAtYourPace]: t(TRANSLATIONS.drinkAtYourPace),
    [DrinkingWindowStatus.Hold]: t(TRANSLATIONS.hold),
    [DrinkingWindowStatus.DrinkOrHold]: t(TRANSLATIONS.drinkOrHold),
    [DrinkingWindowStatus.DrinkNow]: t(TRANSLATIONS.drinkNow),
    [DrinkingWindowStatus.PastItsPeak]: t(TRANSLATIONS.pastItsPeak),
  };

  return (
    <div className={styles.drinkingWindow}>
      <DrinkingWindowIcon status={status} />
      <div className={styles.text}>
        <Typography type={TypographyType.LabelLarge}>{textMap[status]}</Typography>
        {drinkingWindow.start_year || drinkingWindow.end_year ? (
          <Typography type={TypographyType.BodyMedium}>
            · {drinkingWindow.start_year} - {drinkingWindow.end_year}
          </Typography>
        ) : null}
      </div>
    </div>
  );
}
