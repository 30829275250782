import { priceToString } from '@lib/numbers';
import { Typography, TypographyType } from '@vivino/js-react-common-ui';
import { Currency, useI18N } from '@vivino/js-web-common';
import React from 'react';

import styles from './averagePrice.module.scss';

const TRANSLATIONS = {
  average: 'cellars.show.average',
};

interface AveragePriceProps {
  price: number;
  currency: Currency;
}

export default function AveragePrice({ price, currency }: AveragePriceProps) {
  const { t } = useI18N();

  return (
    <div className={styles.averagePrice}>
      <Typography type={TypographyType.TitleExtraLarge}>
        {priceToString({ amount: price, currency })}
      </Typography>
      <Typography type={TypographyType.BodyMedium} className={styles.average}>
        {t(TRANSLATIONS.average)}
      </Typography>
    </div>
  );
}
