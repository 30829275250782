import { useBreakpoint } from '@vivino/js-react-common-ui';
import cx from 'classnames';
import React from 'react';
import { ComponentSizes } from 'vivino-js/commonPropTypes';
import { CommunityReviewSkeleton } from 'vivino-ui/components/CommunityReviews/components/CommunityReview';
import { HighlightSkeleton } from 'vivino-ui/components/Highlight';
import Bottle from 'vivino-ui/icons/BottleDummy';

import styles from './wineCard.scss';
import skeletonStyles from './wineCard.skeleton.scss';

export interface WineCardSkeletonProps {
  size?: ComponentSizes;
  isLoadingReview?: boolean;
  isLoadingHighlight?: boolean;
  hasBlurb?: boolean;
  isSuperCard?: boolean;
}

const WineCardSkeleton = ({
  size = ComponentSizes.Base,
  isLoadingReview = false,
  isLoadingHighlight = false,
  hasBlurb = false,
  isSuperCard = false,
}: WineCardSkeletonProps) => {
  const { isMobile } = useBreakpoint();
  // Render the large card only when size is Large and not mobile
  const finalSize =
    size === ComponentSizes.Large && !isMobile ? ComponentSizes.Large : ComponentSizes.Base;

  const renderInfoSection = () => (
    <div className={styles.infoSection}>
      <div className={skeletonStyles.textLine}></div>
      <div className={skeletonStyles.textLine}></div>
      <div className={skeletonStyles.location}>
        <div className={skeletonStyles.block}></div>
        <div className={skeletonStyles.textLine}></div>
      </div>
    </div>
  );

  return (
    <div className={cx(styles.wineCard, styles[finalSize])}>
      <div className={cx(styles.wineCardContent, skeletonStyles.wineCardContent)}>
        {isSuperCard && (
          <div className={skeletonStyles.superWineCard}>
            <div className={skeletonStyles.superWineCardContent}>
              <div className={skeletonStyles.textLine}></div>
              <div className={skeletonStyles.textLine}></div>
            </div>
          </div>
        )}
        <div className={styles.topSection}>
          <div className={styles.bottleSection}>
            <div className={cx(styles.bottleShot, skeletonStyles.bottleShot)}>
              <Bottle className={skeletonStyles.bottle} />
            </div>
          </div>
          {finalSize === ComponentSizes.Large && (
            <div className={styles.infoColumn}>{renderInfoSection()}</div>
          )}
          <div className={styles.ratingAndAddtoCart}>
            <div className={skeletonStyles.button} />
          </div>
        </div>
        {finalSize === ComponentSizes.Base && renderInfoSection()}
        {hasBlurb && (
          <div className={cx(skeletonStyles.blurb, styles.bottomItemMargin, styles.blurb)}>
            <div className={skeletonStyles.textLine}></div>
            <div className={skeletonStyles.textLine}></div>
          </div>
        )}
        {isLoadingHighlight && (
          <HighlightSkeleton className={cx(styles.bottomItemMargin, styles.highlight)} />
        )}
        {isLoadingReview && (
          <div className={styles.bottomItemMargin}>
            <CommunityReviewSkeleton className={styles.review} />
          </div>
        )}
      </div>
    </div>
  );
};

export default WineCardSkeleton;
