import PropTypes from 'prop-types';
import React, { useState } from 'react';

export const VintageContext = React.createContext({
  vintage: {},
});

export const VintageProvider = ({ vintage, children }) => {
  const [vintageState] = useState(vintage);
  return (
    <VintageContext.Provider value={{ vintage: vintageState }}>{children}</VintageContext.Provider>
  );
};

VintageProvider.propTypes = {
  vintage: PropTypes.object,
  children: PropTypes.node,
};
