import { createCellarEvent } from '@lib/api/cellar';
import { FullUserVintage, RubyLibFullVintage, useI18N } from '@vivino/js-web-common';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { trackEvent } from 'vivino-js/analytics';

interface CellarContextProviderProps {
  cellarId: number;
  cellarName: string;
  vintage?: RubyLibFullVintage;
  userVintage?: FullUserVintage;
}

interface CellarContextType {
  cellarId: number;
  cellarName: string;
  drawerOpen: boolean;
  drawerState: {
    vintage: RubyLibFullVintage | null;
    userVintage: FullUserVintage | null;
  };
  setDrawerState: React.Dispatch<
    React.SetStateAction<{
      vintage: RubyLibFullVintage | null;
      userVintage: FullUserVintage | null;
    }>
  >;

  handleCreateCellarEvent: (difference: number, comment?: string) => Promise<void>;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
}

const TRANSLATIONS_PATH = 'components.cellar.drawer';
const TRANSLATIONS = {
  addedBottle: `${TRANSLATIONS_PATH}.notifications.added.one`,
  addedBottles: `${TRANSLATIONS_PATH}.notifications.added.other`,
  removedBottle: `${TRANSLATIONS_PATH}.notifications.removed.one`,
  removedBottles: `${TRANSLATIONS_PATH}.notifications.removed.other`,
};

export const CellarContext = React.createContext<CellarContextType | null>(null);

export const useCellarContext = () => {
  const context = React.useContext(CellarContext);
  if (!context) {
    throw new Error('useCellarContext must be used within a CellarContextProvider');
  }
  return context;
};

export const CellarContextProvider: React.FC<CellarContextProviderProps> = ({
  cellarId,
  cellarName,
  vintage,
  userVintage,
  children,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [drawerState, setDrawerState] = useState<{
    vintage: RubyLibFullVintage | null;
    userVintage: FullUserVintage | null;
  }>({ vintage, userVintage });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { t } = useI18N();

  function handleDrawerOpen() {
    setDrawerOpen(true);
  }

  function handleDrawerClose() {
    setDrawerOpen(false);
  }

  function handleCreateCellarEvent(difference: number, comment?: string) {
    if (!drawerState.vintage) {
      throw new Error('Vintage is missing');
    }

    const type = difference > 0 ? 'add' : 'consume';
    const unsignedDifference = Math.abs(difference);

    const addBottleMessage =
      unsignedDifference === 1
        ? t(TRANSLATIONS.addedBottle, { count: unsignedDifference, cellar_name: cellarName })
        : t(TRANSLATIONS.addedBottles, { count: unsignedDifference, cellar_name: cellarName });

    const removeBottleMessage =
      unsignedDifference === 1
        ? t(TRANSLATIONS.removedBottle, { count: unsignedDifference, cellar_name: cellarName })
        : t(TRANSLATIONS.removedBottles, { count: unsignedDifference, cellar_name: cellarName });

    const successMessage = difference > 0 ? addBottleMessage : removeBottleMessage;

    return new Promise<void>((resolve, reject) => {
      createCellarEvent({
        cellarId,
        vintageId: drawerState.vintage.id,
        type: type,
        count: Math.abs(difference),
        comment,
      })
        .then(() => {
          enqueueSnackbar(successMessage, {
            variant: 'success',
            onClose: () => closeSnackbar(),
          });

          trackEvent({
            event: 'Cellar - Entry - Update',
            props: {
              previous_bottle_count: drawerState.userVintage?.cellar_count,
              new_bottle_count: drawerState.userVintage?.cellar_count + difference,
            },
          });
          resolve();
        })
        .catch((error) => {
          enqueueSnackbar(error.message, {
            variant: 'error',
            onClose: () => closeSnackbar(),
          });

          reject(error);
        });
    });
  }

  const value = {
    cellarId,
    cellarName,
    drawerOpen,
    drawerState,
    setDrawerState,
    handleCreateCellarEvent,
    handleDrawerClose,
    handleDrawerOpen,
  };

  return <CellarContext.Provider value={value}>{children}</CellarContext.Provider>;
};
