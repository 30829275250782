import { apiGet } from 'vivino-js/apiClient';

export function fetchUserVintage(userVintageId) {
  return apiGet({
    url: `/api/user_vintages/${userVintageId}`,
  });
}

export function fetchUserVintagesByVintageId({ vintageId }) {
  return apiGet({
    url: `/api/vintages/${vintageId}/user_vintages`,
  });
}
