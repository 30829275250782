import { useBreakpoint } from '@vivino/js-react-common-ui';
import React from 'react';
import { Col, Row } from 'vivino-ui/components/Grid';
import WineCardSkeleton from 'vivino-ui/components/WineCard/WineCard.skeleton';

import styles from './winerySummary.skeleton.scss';

function WinerySummarySkeleton() {
  const { isTablet, isDesktop } = useBreakpoint();

  return (
    <>
      <Row>
        <Col desktop={4} tablet={6} mobile={6}>
          <div className={styles.iconWithText}>
            <div className={styles.icon} />
            <div className={styles.text} />
          </div>
          <div className={styles.iconWithText}>
            <div className={styles.icon} />
            <div className={styles.text} />
          </div>
          <div className={styles.iconWithText}>
            <div className={styles.icon} />
            <div className={styles.text} />
          </div>
          <div className={styles.iconWithText}>
            <div className={styles.icon} />
            <div className={styles.text} />
          </div>
          <div className={styles.bigText} />
          <div className={styles.smallText} />
        </Col>
        <Col desktop={8} tablet={6} mobile={6}>
          <div className={styles.googleMapsBlop} />
        </Col>
      </Row>
      <div>
        {isTablet ? (
          <div className={styles.tabletSlider}>
            <div className={styles.tabletWineCard}>
              <WineCardSkeleton />
            </div>
            <div className={styles.tabletWineCard}>
              <WineCardSkeleton />
            </div>
            <div className={styles.tabletWineCard}>
              <WineCardSkeleton />
            </div>
          </div>
        ) : (
          <Row className={styles.row}>
            <Col desktop={3} mobile={3}>
              <WineCardSkeleton />
            </Col>
            <Col desktop={3} mobile={3}>
              <WineCardSkeleton />
            </Col>
            {isDesktop && (
              <>
                <Col desktop={3}>
                  <WineCardSkeleton />
                </Col>
                <Col desktop={3}>
                  <WineCardSkeleton />
                </Col>
              </>
            )}
          </Row>
        )}
        <div className={styles.smallText} />
      </div>
    </>
  );
}

export default WinerySummarySkeleton;
