import { CrossIcon } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import Anchor from 'vivino-js/atoms/Anchor/Anchor.presentational';
import DisableScrolling from 'vivino-js/components/DisableScrolling/DisableScrolling.presentational';
import t from 'vivino-js/translationString';
import Sentinel from 'vivino-ui/atoms/Sentinel';

import styles from './baseModal.scss';
import crossTransitions from './crossTransitions.scss';
import transitions from './transitions.scss';
import windowTransitions from './windowTransitions.scss';

export const POSITION_TOP = 'POSITION_TOP';
export const PADDING_NONE = 'PADDING_NONE';

export const ModalSize = {
  Standard: Symbol('Standard'),
  Small: Symbol('Small'),
  LightBox: Symbol('LightBox'),
};

const TRANSLATIONS = {
  close: 'components.shared.modals.base_modal.close',
};

const ESCAPE_KEY = 27;
const LEFT_MOUSE_KEY = 0;

export const CLOSE_TRIGGERS = {
  X_BUTTON: 'x_button',
  BACKDROP: 'tap_outside',
  ESC_KEY: 'esc_key',
};

const BaseModal = ({
  onClose,
  onIntersect,
  children,
  padding,
  position,
  className,
  closeBtnClassName,
  show,
  scrollToTopUpdater,
  size = ModalSize.Standard,
}) => {
  const backdropEl = useRef();
  const windowRef = useRef();
  const [showWindow, setShowWindow] = useState(false);
  const isLightBox = size === ModalSize.LightBox;

  useEffect(() => {
    // window css transition will not work with show
    // since show is used to mount the component
    setShowWindow(show);
  }, [show]);

  useEffect(() => {
    const closeOnEscape = (e) => {
      if (e.keyCode === ESCAPE_KEY) {
        onClose({ trigger: CLOSE_TRIGGERS.ESC_KEY });
      }
    };

    document.addEventListener('keydown', closeOnEscape);
    return () => {
      document.removeEventListener('keydown', closeOnEscape);
    };
  }, []);

  useEffect(() => {
    if (windowRef?.current?.scrollTop) {
      windowRef.current.scrollTop = 0;
    }
  }, [scrollToTopUpdater]);

  const handleBackdropClick = (e) => {
    if (e.target === backdropEl.current && e.button === LEFT_MOUSE_KEY) {
      e.stopPropagation();
      onClose({ trigger: CLOSE_TRIGGERS.BACKDROP });
    }
  };

  const handleCloseClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClose({ trigger: CLOSE_TRIGGERS.X_BUTTON });
  };

  const optionalClasses = {
    [styles.positionTop]: position === POSITION_TOP,
    [styles.themeNoPadding]: padding === PADDING_NONE,
    [styles.small]: size === ModalSize.Small,
  };

  return ReactDOM.createPortal(
    <CSSTransition in={show} timeout={300} classNames={{ ...transitions }} unmountOnExit>
      <DisableScrolling>
        <div
          className={cx(styles.backdrop, { [styles.lightBox]: isLightBox })}
          onMouseDown={handleBackdropClick}
          onTouchEnd={handleBackdropClick}
          ref={backdropEl}
          data-testid="baseModalBackdrop"
        >
          {isLightBox && (
            <CSSTransition in={showWindow} timeout={600} classNames={{ ...crossTransitions }}>
              <div className={styles.closeContainer} data-testid="baseModalContainer">
                <Anchor
                  href="#"
                  onClick={handleCloseClick}
                  className={cx(styles.close, closeBtnClassName)}
                  aria-label={t(TRANSLATIONS.close)}
                  data-testid="baseModalCloseButton"
                >
                  <CrossIcon />
                </Anchor>
              </div>
            </CSSTransition>
          )}
          <CSSTransition in={showWindow} timeout={500} classNames={{ ...windowTransitions }}>
            <div className={cx(styles.window, optionalClasses, className)} ref={windowRef}>
              {!isLightBox && (
                <div className={styles.closeContainer} data-testid="baseModalContainer">
                  <Anchor
                    href="#"
                    onClick={handleCloseClick}
                    className={cx(styles.close, closeBtnClassName)}
                    aria-label={t(TRANSLATIONS.close)}
                    data-testid="baseModalCloseButton"
                  >
                    <CrossIcon />
                  </Anchor>
                </div>
              )}
              {children}
              {isLightBox && windowRef?.current && (
                <Sentinel
                  onIntersect={onIntersect}
                  options={{
                    root: windowRef.current,
                    rootMargin: '600px 0px',
                    threshold: 0.1,
                  }}
                />
              )}
            </div>
          </CSSTransition>
        </div>
      </DisableScrolling>
    </CSSTransition>,
    document.getElementById('baseModal')
  );
};

BaseModal.propTypes = {
  className: PropTypes.string,
  closeBtnClassName: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  padding: PropTypes.oneOf([PADDING_NONE]),
  position: PropTypes.oneOf([POSITION_TOP]),

  // Only applies for lightbox
  // This is for infinite scrolling
  onIntersect: PropTypes.func,
  // This is a number that increases to let the modal scroll to top when a filter is changed
  scrollToTopUpdater: PropTypes.number,

  // These sizes are specified by design
  // And based on the sizes of the grid
  // Standard is what corresponds to 6 columns on Desktop and 10 on Tablet
  // Small is what corresponds to 6 columns on Desktop and 6 on Tablet
  // LightBox is Standard size but extends to the bottom with props for infinite scrolling
  // Both are fullscreen on Mobile
  size: PropTypes.oneOf([ModalSize.Standard, ModalSize.Small, ModalSize.LightBox]),
};

export default BaseModal;
