import {
  Button,
  ButtonTheme,
  MinusIcon,
  PlusIcon,
  TextArea,
  Typography,
  TypographyType,
} from '@vivino/js-react-common-ui';
import { FullUserVintage, useI18N } from '@vivino/js-web-common';
import React, { useState } from 'react';

import styles from './cellarBottleQuantity.module.scss';

const TRANSLATIONS_PATH = 'components.cellar.drawer';
const TRANSLATIONS = {
  inCellar: `${TRANSLATIONS_PATH}.in_cellar`,
  add_bottle_button_one: `${TRANSLATIONS_PATH}.add_bottle_button.one`,
  add_bottle_button_other: `${TRANSLATIONS_PATH}.add_bottle_button.other`,
  remove_bottle_button_one: `${TRANSLATIONS_PATH}.remove_bottle_button.one`,
  remove_bottle_button_other: `${TRANSLATIONS_PATH}.remove_bottle_button.other`,
  cellar_note_placeholder: `${TRANSLATIONS_PATH}.cellar_note_placeholder`,
};

interface CellarBottleQuantityProps {
  cellarName: string;
  userVintage: FullUserVintage | null;
  minBottles?: number;
  maxBottles?: number;
  handleCreateCellarEvent: (difference: number, comment?: string) => void;
}

export default function CellarBottleQuantity({
  cellarName,
  userVintage,
  minBottles = 0,
  maxBottles = 999,
  handleCreateCellarEvent,
}: CellarBottleQuantityProps) {
  const MAX_CELLAR_NOTE_LENGTH = 512;
  const initialQuantity = userVintage?.cellar_count || 0;

  const [quantity, setQuantity] = useState(initialQuantity);
  const difference = quantity - initialQuantity;
  const unsignedDifference = Math.abs(difference);

  const [cellarNote, setCellarNote] = useState(null);

  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCellarNote(event.target.value);
  };

  const createCellarEvent = () => {
    handleCreateCellarEvent(difference, cellarNote);
    setCellarNote(null);
  };

  const showCellarNote = () => initialQuantity !== quantity;

  const { t } = useI18N();

  const butttonText = () => {
    if (quantity > initialQuantity) {
      return unsignedDifference === 1
        ? t(TRANSLATIONS.add_bottle_button_one, { count: unsignedDifference })
        : t(TRANSLATIONS.add_bottle_button_other, { count: unsignedDifference });
    }

    if (quantity < initialQuantity) {
      return unsignedDifference === 1
        ? t(TRANSLATIONS.remove_bottle_button_one, { count: unsignedDifference })
        : t(TRANSLATIONS.remove_bottle_button_other, { count: unsignedDifference });
    }
  };

  return (
    <div className={styles.cellarEvent}>
      <div className={styles.cellarBottleQuantity}>
        <Typography type={TypographyType.TitleExtraLarge}>
          {t(TRANSLATIONS.inCellar, { cellar_name: cellarName })}
        </Typography>
        <div className={styles.controls}>
          <Button
            dataTestId="remove-button"
            className={styles.button}
            disabled={quantity === minBottles}
            iconButton
            theme={ButtonTheme.Plain}
            onClick={() => setQuantity(quantity - 1)}
          >
            <MinusIcon />
          </Button>

          <Typography
            data-testid="quantity"
            className={styles.quantity}
            type={TypographyType.TitleExtraLarge}
          >
            {quantity}
          </Typography>
          <Button
            dataTestId="add-button"
            className={styles.button}
            disabled={quantity === maxBottles}
            iconButton
            theme={ButtonTheme.Plain}
            onClick={() => setQuantity(quantity + 1)}
          >
            <PlusIcon />
          </Button>
        </div>
      </div>

      {showCellarNote() && (
        <>
          <TextArea
            data-testid="cellar-note"
            fullWidth
            value={cellarNote}
            placeholder={t(TRANSLATIONS.cellar_note_placeholder)}
            onChange={handleNoteChange}
            maxLength={MAX_CELLAR_NOTE_LENGTH}
          />

          <Button
            dataTestId="change-quantity-button"
            theme={ButtonTheme.Primary}
            onClick={() => createCellarEvent()}
          >
            {butttonText()}
          </Button>
        </>
      )}
    </div>
  );
}
