import AveragePrice from '@components/AveragePrice/AveragePrice';
import AverageRating from '@components/AverageRating/AverageRating';
import VintageInfo from '@components/VintageInfo/VintageInfo';
import { Currency, FullUserVintage, RubyLibFullVintage, vintageUrl } from '@vivino/js-web-common';
import React from 'react';

import styles from './vintageCoreData.module.scss';

interface VintageCoreDataProps {
  compact?: boolean;
  vintage: RubyLibFullVintage;
  userVintage?: FullUserVintage;
  averagePrice?: number;
  averagePriceCurrency?: Currency;
}

export default function VintageCoreData({
  compact = false,
  vintage,
  userVintage,
  averagePrice,
  averagePriceCurrency,
}: VintageCoreDataProps) {
  const hasAveragePrice = averagePrice && averagePrice > 0;

  const userLabelScan = userVintage?.image?.variations?.large;
  const imageLocation = userLabelScan || vintage?.image?.variations?.large;

  if (compact) {
    return (
      <div className={styles.vintageCoreData} data-testid="compact-vintage-core-data">
        <div className={styles.vintageInformation}>
          <a onClick={(e) => e.stopPropagation()} href={vintageUrl({ vintage })}>
            <img
              loading="lazy"
              className={styles.labelImageCompact}
              src={imageLocation}
              alt={vintage.name}
              data-testid="vintage-label-image-compact"
            />
          </a>
          <div className={styles.vintage}>
            <VintageInfo vintage={vintage} />
          </div>
        </div>

        <div className={styles.averageBadgesCompact}>
          <div className={styles.badge}>
            <AverageRating vintage={vintage} />
          </div>

          {hasAveragePrice && (
            <div className={styles.badge}>
              <AveragePrice price={averagePrice} currency={averagePriceCurrency} />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.vintageCoreData} data-testid="vintage-core-data">
      <div className={styles.vintageInformation}>
        <a onClick={(e) => e.stopPropagation()} href={vintageUrl({ vintage })}>
          <img
            loading="lazy"
            className={styles.labelImage}
            src={imageLocation}
            alt={vintage.name}
            data-testid="vintage-label"
          />
        </a>
        <div className={styles.vintage}>
          <VintageInfo vintage={vintage} />
          <div className={styles.averageBadges}>
            <div className={styles.badge}>
              <AverageRating vintage={vintage} />
            </div>
            {hasAveragePrice && (
              <div className={styles.badge}>
                <AveragePrice price={averagePrice} currency={averagePriceCurrency} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.averageBadgesMobile}>
        <div className={styles.badge}>
          <AverageRating vintage={vintage} />
        </div>
        {hasAveragePrice && (
          <div className={styles.badge}>
            <AveragePrice price={averagePrice} currency={averagePriceCurrency} />
          </div>
        )}
      </div>
    </div>
  );
}
