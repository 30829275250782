import React from 'react';

const VerticalFlagOnIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path
      d="M11.3164,1.5H4.6836A1.726,1.726,0,0,0,3,3.2622V14a.5.5,0,0,0,.8125.3906L8,11.04l4.1875,3.3506A.5.5,0,0,0,13,14V3.2622A1.726,1.726,0,0,0,11.3164,1.5Z"
      fill="#1e1e1e"
    />
  </svg>
);

export default VerticalFlagOnIcon;
