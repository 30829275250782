import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

export const Location = (props: SvgIconProps) => (
  <SvgIcon {...props} fill="none">
    <path
      d="M12 22c4-4 8-7.582 8-12a8 8 0 1 0-16 0c0 4.418 4 8 8 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const LocationFill = (props: SvgIconProps) => (
  <SvgIcon {...props} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 10a8.75 8.75 0 0 1 17.5 0c0 2.447-1.11 4.607-2.656 6.614-1.293 1.68-2.948 3.32-4.616 4.974l-.948.942a.75.75 0 0 1-1.06 0l-.948-.942c-1.668-1.654-3.323-3.294-4.616-4.974C4.36 14.607 3.25 12.447 3.25 10ZM15 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      fill="currentColor"
    />
  </SvgIcon>
);
