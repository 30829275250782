import { Anchor, UserAlias, UserAvatar } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { price, vintage } from 'vivino-js/apiPropTypes';
import t from 'vivino-js/translationString';
import { editorNoteUrl } from 'vivino-js/vivinoUrls';
import TruncatedText, { NumTruncateLines } from '@components/TruncatedText/TruncatedText';

import styles from './editorNote.scss';

const TRANSLATIONS = {
  wineEditorAtVivino: 'components.shared.editor_note.wine_editor_at_vivino',
};

const EditorNote = ({ user, note, vintage, price, className }) => {
  if (!note) {
    return null;
  }
  note = note.trim();

  return (
    <div
      className={cx(
        styles.editorNote,
        {
          [styles.noUserInfo]: !user,
        },
        className
      )}
    >
      <Anchor href={editorNoteUrl({ vintage, price })} className={styles.reviewContent}>
        <TruncatedText
          numLines={NumTruncateLines.Three}
          dataTestId="reviewNote"
          className={styles.editorNoteText}
        >
          {note}
        </TruncatedText>
      </Anchor>
      {user && (
        <div className={styles.userInfo}>
          <UserAvatar user={user} className={styles.avatar} />
          <div className={styles.userTextInfo}>
            <UserAlias user={user} className={styles.userAlias} />
            <span className={styles.userTitle}>{t(TRANSLATIONS.wineEditorAtVivino)}</span>
          </div>
        </div>
      )}
    </div>
  );
};

EditorNote.propTypes = {
  user: PropTypes.object,
  note: PropTypes.string,
  vintage: vintage,
  price: price,
  className: PropTypes.string,
};

export default EditorNote;
