import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';
import { createOfferSubscription } from 'vivino-js/api/offerSubscription';
import { getUserHasOfferSubscription } from 'vivino-js/helpers/offerSubscriptionHelper';

const preloadedUserHasOfferSubscription = getUserHasOfferSubscription();

/**
 * Does the current user have an offer subscription? This information is loaded from
 * the window preloaded state *userHasOfferSubscription*.
 *
 * Please check user_helper.rb#user_has_offer_subscription?
 *
 * If we need to rely in dinamically loaded data, we can also implement the call
 * client side.
 *
 * It also offers a method to subscribe the user for offers.
 */
export const OfferSubscriptionContext = createContext({
  isSubscribedForOffers: false,
  updateOfferSubscriptionContext: undefined,
});

export const OfferSubscriptionContextProvider = ({
  isSubscribedForOffers = preloadedUserHasOfferSubscription,
  children,
}) => {
  const [isSubscribedForOffersState, setIsSubscribedForOffersState] = useState(
    isSubscribedForOffers
  );

  /** makes and API POST request and sets the the flag to true on success */
  const updateOfferSubscriptionContext = ({ email, event }) => {
    return createOfferSubscription({ email, event })
      .then(() => {
        setIsSubscribedForOffersState(true);
      })
      .catch(() => {
        setIsSubscribedForOffersState(false);
      });
  };

  return (
    <OfferSubscriptionContext.Provider
      value={{
        isSubscribedForOffers: isSubscribedForOffersState,
        updateOfferSubscriptionContext,
      }}
    >
      {children}
    </OfferSubscriptionContext.Provider>
  );
};

OfferSubscriptionContextProvider.propTypes = {
  isSubscribedForOffers: PropTypes.bool,
  children: PropTypes.node,
};

export const OfferSubscriptionContextConsumer = OfferSubscriptionContext.Consumer;
