import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { price } from 'vivino-js/apiPropTypes';
import { isXDOPrice } from 'vivino-js/helpers/price';
import DiscountPercentLabel from 'vivino-ui/atoms/DiscountPercentLabel';

import styles from './discountSplash.scss';

export const SPLASH_DEAL_CLASS = 'deal';
export const SPLASH_DISCOUNT_CLASS = 'discount';

const DiscountSplash = ({ price, className = '' }) => {
  const splashTypeClass = isXDOPrice(price) ? SPLASH_DEAL_CLASS : SPLASH_DISCOUNT_CLASS;
  const [height, setHeight] = useState(0);
  const topElementRef = useRef(null);
  const contentElementRef = useRef(null);

  useLayoutEffect(() => {
    // set the height of the container dynamically depending on the content's width
    const currentWidth = topElementRef.current?.getBoundingClientRect()?.width;
    const contentWidth = contentElementRef.current?.getBoundingClientRect()?.width;
    const newWidth = Math.max(contentWidth, currentWidth);
    setHeight(newWidth);
  }, [price]);

  const discountPercentLabel = (
    <DiscountPercentLabel price={price} className={styles.text} percentClass={styles.value} />
  );
  if (!ReactDOMServer.renderToString(discountPercentLabel)) {
    return null;
  }

  return (
    <div
      ref={topElementRef}
      style={{ width: `${height}px`, height: `${height}px` }}
      className={cx(className, styles.discountSplash, styles[splashTypeClass])}
    >
      <div ref={contentElementRef} className={styles.discountContent}>
        {discountPercentLabel}
      </div>
    </div>
  );
};

DiscountSplash.propTypes = {
  price,
  className: PropTypes.string,
};

export default DiscountSplash;
