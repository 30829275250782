import { Button, ButtonTheme } from '@vivino/js-react-common-ui';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { track, trackAddToCart } from 'vivino-js/analytics';
import { createCart, removeCart } from 'vivino-js/api/carts';
import { price } from 'vivino-js/apiPropTypes';
import { formSize } from 'vivino-js/commonPropTypes';
import { CartItemSourceContext } from 'vivino-js/context/CartItemSourceContext';
import { CartsContext } from 'vivino-js/context/CartsContext';
import { MerchandizingCampaignContext } from 'vivino-js/context/MerchandizingContext';
import { getCartItemSource, sanitiseCartItemSource } from 'vivino-js/helpers/cartItemSources';
import t from 'vivino-js/translationString';

const TRANSLATIONS = {
  addToCart: 'components.shared.add_to_cart_button.add_to_cart',
};

const AddMultipleToCartButton = ({
  className,
  items,
  onError,
  size,
  merchantId,
  fullWidth = true,
  shouldDeleteExistingCartFromMerchant = false,
}) => {
  const [working, setWorking] = useState(false);
  const { carts } = useContext<CartsContext>(CartsContext);
  const cartItemSourceType = useContext(CartItemSourceContext);
  const campaignId = useContext(MerchandizingCampaignContext);
  const source = sanitiseCartItemSource(getCartItemSource(cartItemSourceType, campaignId));

  const trackAddedToCart = ({ price, seenVintageId, bottleCount }) => {
    trackAddToCart({
      currencyCode: price.currency.code,
      quantity: bottleCount,
      priceAmount: price.amount,
      priceType: price.type,
      vintageId: seenVintageId,
      source,
    });
  };

  const onCartsCreateSuccess = (cartId) => {
    window.location.assign(`/carts/${cartId}`);
  };

  const addAllItemsToCart = async () => {
    const newCarts = [];

    // When we're adding a mixed case card we want to make sure that the only items in the cart
    // is the mixed case items. Therefore we delete carts from the same merchant.
    if (shouldDeleteExistingCartFromMerchant) {
      const cartFromSameMerchant = carts.find((cart) => cart?.merchant_id === merchantId);
      if (cartFromSameMerchant) {
        await removeCart({ cartId: cartFromSameMerchant.id });
      }
    }

    let totalPrice = 0;

    // Using a for of loop here, to have the createCart api calls happen sequentially.
    for (const { price, seenVintageId, bottleCount } of items) {
      const { cart } = await createCart({
        bottleCount,
        priceId: price.id,
        seenVintageId,
        source,
      });
      totalPrice += bottleCount * price.amount;
      trackAddedToCart({ price, seenVintageId, bottleCount });
      newCarts.push(cart);
    }

    const trackingProps = {
      currencyCode: items[0].price.currency.code,
      priceType: items[0].price.type,
      merchantId: merchantId,
      totalPrice,
      source,
    };

    track({
      event: 'AddMultipleToCart',
      props: trackingProps,
    });

    return newCarts;
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setWorking(true);

    addAllItemsToCart()
      .then((carts) => {
        onCartsCreateSuccess(carts?.[0]?.id);
      })
      .catch((error) => {
        setWorking(false);
        if (onError) {
          onError(error.message);
        } else {
          window.alert(error.message);
        }
      });
  };

  return (
    <Button
      theme={ButtonTheme.Accent}
      className={className}
      working={working}
      onClick={handleClick}
      aria-label={t(TRANSLATIONS.addToCart)}
      size={size}
      fullWidth={fullWidth}
    >
      {t(TRANSLATIONS.addToCart)}
    </Button>
  );
};

AddMultipleToCartButton.propTypes = {
  className: PropTypes.string,
  merchantId: PropTypes.number,
  fullWidth: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      bottleCount: PropTypes.number,
      price: price,
      seenVintageId: PropTypes.number.isRequired,
    })
  ),
  onError: PropTypes.func,
  size: formSize,
  shouldDeleteExistingCartFromMerchant: PropTypes.bool,
};

export default AddMultipleToCartButton;
