import { TextArea } from '@vivino/js-react-common-ui';
import { Anchor, AnchorStyle, getWindow, UserAlias, UserAvatar } from '@vivino/js-web-common';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CommonPropTypes, ComponentSizes } from 'vivino-js/commonPropTypes';
import { isCurrentUser } from 'vivino-js/helpers/user';
import t from 'vivino-js/translationString';
import TimeAgo from 'vivino-js/utilities/TimeAgo';

import styles from './comment.scss';

const TRANSLATIONS_PATH = 'components.shared.community_reviews';
const TRANSLATIONS = {
  editComment: `${TRANSLATIONS_PATH}.edit`,
  deleteComment: `${TRANSLATIONS_PATH}.delete`,
  cancel: 'common.cancel',
  confirmDeleteComment: `${TRANSLATIONS_PATH}.confirm_delete_comment`,
  leaveAComment: 'components.shared.comments_section.leave_a_comment',
};

const Comment = ({ comment, size = ComponentSizes.Base, onUpdateComment, onDeleteComment }) => {
  const { id, user, text, tagged_text, created_at } = comment;

  const [isEditMode, setIsEditMode] = useState(false);
  const [commentInput, setCommentInput] = useState(text);

  const handleCommentChange = (e) => {
    setCommentInput(e.currentTarget.value);
  };

  const handleEditCommentSubmit = async (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      if (!commentInput.trim()) {
        return;
      }
      await onUpdateComment({ id, commentInput });
      setIsEditMode(false);
    }
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setCommentInput(text);
  };

  if (isEditMode) {
    return (
      <div>
        <div className={styles.commentContainer}>
          <UserAvatar user={user} className={styles.avatar} />
          <TextArea
            fullWidth
            value={commentInput}
            onChange={handleCommentChange}
            onKeyDown={handleEditCommentSubmit}
            placeholder={t(TRANSLATIONS.leaveAComment)}
            className={styles.textArea}
          />
        </div>
        <Anchor
          onClick={handleCancel}
          anchorStyle={AnchorStyle.Base}
          className={styles.cancelComment}
        >
          {t(TRANSLATIONS.cancel)}
        </Anchor>
      </div>
    );
  }

  const handleDeleteComment = () => {
    if (getWindow().confirm(t(TRANSLATIONS.confirmDeleteComment))) {
      onDeleteComment(id);
    }
  };

  return (
    <div className={styles.commentContainer}>
      <UserAvatar user={user} className={styles.avatar} />
      <div>
        <p className={styles.comment} dangerouslySetInnerHTML={{ __html: tagged_text }} />
        <div className={styles.name}>
          <UserAlias user={user} size={size} />
          <span className={styles.timeAgo}>
            <TimeAgo date={created_at} />
          </span>
          {isCurrentUser(user) && (
            <>
              <Anchor
                onClick={() => setIsEditMode(true)}
                anchorStyle={AnchorStyle.Base}
                className={styles.editComment}
              >
                {t(TRANSLATIONS.editComment)}
              </Anchor>
              <Anchor
                onClick={handleDeleteComment}
                anchorStyle={AnchorStyle.Base}
                className={styles.deleteComment}
              >
                {t(TRANSLATIONS.deleteComment)}
              </Anchor>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Comment.propTypes = {
  comment: PropTypes.object,
  size: CommonPropTypes.componentSize,
  onUpdateComment: PropTypes.func,
  onDeleteComment: PropTypes.func,
};

export default Comment;
