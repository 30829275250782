import React from 'react';

import cx from 'classnames';
import { StarFillIcon } from '@vivino/js-react-common-ui';

import styles from './star.module.scss';

interface StarProps {
  rating: number;
  size?: StarSize;
}

export enum StarSize {
  small = 'small',
  regular = 'regular',
}

export default function Star({ rating, size = StarSize.regular }: StarProps) {
  const floorRating = Math.floor(rating);

  return (
    <StarFillIcon
      className={cx(styles.star, size ? styles.small : styles.regular)}
      data-rating={floorRating}
      data-testid="star"
    />
  );
}
