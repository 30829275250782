import { apiDelete, apiPost } from 'vivino-js/apiClient';

export function addToWishlist({ vintageId }) {
  return apiPost({
    url: `/api/vintages/${vintageId}/wishlist`,
  });
}

export function removeFromWishlist({ vintageId }) {
  return apiDelete({
    url: `/api/vintages/${vintageId}/wishlist`,
  });
}
