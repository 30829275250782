import { RubyLibCellarList, generateQueryUrl } from '@vivino/js-web-common';
import { apiGet, apiPost } from 'vivino-js/apiClient';

export const fetchCellar = async ({
  cellarId,
  page,
  search,
  sortBy,
  sortOrder,
  signal,
}: {
  cellarId: number;
  page?: number;
  search?: string;
  sortBy?: string;
  sortOrder?: string;
  signal?: AbortSignal;
}): Promise<{ cellar_list: RubyLibCellarList }> => {
  const params = [];

  if (page) {
    params.push({ name: 'page', value: page });
  }

  if (search) {
    params.push({ name: 'search', value: search });
  }

  if (sortBy) {
    params.push({ name: 'sort_by', value: sortBy });
  }

  if (sortOrder) {
    params.push({ name: 'sort_order', value: sortOrder });
  }

  const url = generateQueryUrl({ baseUrl: `/api/cellars/${cellarId}`, params });
  const options = signal ? { signal } : undefined;

  return apiGet({ url, options });
};

interface CreateCellarEventProps {
  cellarId: number;
  vintageId: number;
  type: string;
  count: number;
  comment?: string;
}

export const createCellarEvent = async ({
  cellarId,
  vintageId,
  type,
  count,
  comment,
}: CreateCellarEventProps): Promise<void> => {
  const url = `/api/cellars/${cellarId}/events`;

  return apiPost({
    url,
    body: {
      type,
      vintage_id: vintageId,
      count,
      comment,
    },
  });
};
