import { Button, ButtonTheme, WineCellarIcon } from '@vivino/js-react-common-ui';
import React from 'react';

import styles from './addToCellar.module.scss';

const AddToCellarSkeleton = () => {
  return (
    <div className={styles.addToCellar}>
      <Button startIcon={<WineCellarIcon />} theme={ButtonTheme.PlainQuiet} disabled>
        <div className={styles.skeletonText} />
      </Button>
    </div>
  );
};

export default AddToCellarSkeleton;
