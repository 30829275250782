import {
  getGrapesForVintage,
  getRegionForVintage,
  getWineTypeName,
  getWineryForVintage,
  isUnknownWineType,
} from '@vivino/js-web-common';
import { track } from 'vivino-js/analytics';
import { CART_ITEM_SOURCE_TYPES } from 'vivino-js/context/CartItemSourceContext/CartItemSourceContext';
import { CART_ITEM_SOURCE_DATA_ATTRIBUTE } from 'vivino-js/helpers/cartItemSources';
import t from 'vivino-js/translationString';
import { countryUrl, explorerUrl, regionUrl, wineryUrl } from 'vivino-js/vivinoUrls';

const TRANSLATIONS_PATH = 'common';

const TRANSLATIONS = {
  blend: `${TRANSLATIONS_PATH}.blend`,
};

const getCrumbData = ({ name, url, trackingName, cartItemSourceType = '' }) => {
  return {
    name,
    url,
    'data-cy': `breadcrumb-${trackingName?.toLowerCase()?.replace(/ /g, '')}`,
    onClick: () => {
      track({
        event: `${trackingName} breadcrumb - click`,
      });
    },
    ...(cartItemSourceType && { [CART_ITEM_SOURCE_DATA_ATTRIBUTE]: cartItemSourceType }),
  };
};

const getCountryCrumbData = (vintage) => {
  const region = getRegionForVintage(vintage);

  // There are cases where region is null
  // https://tickets.vivino.com/issues/47020
  if (!region) {
    return null;
  }

  const country = region.country;

  return getCrumbData({
    name: country.name,
    url: countryUrl({ country }),
    trackingName: 'Country',
  });
};

const getRegionCrumbData = (vintage) => {
  const region = getRegionForVintage(vintage);

  // There are cases where region is null
  // https://tickets.vivino.com/issues/47020
  if (!region) {
    return null;
  }

  return getCrumbData({ name: region.name, url: regionUrl({ region }), trackingName: 'Region' });
};

const getWineryCrumbData = (vintage) => {
  const winery = getWineryForVintage(vintage);

  return (
    winery &&
    getCrumbData({
      name: winery.name,
      url: wineryUrl({ winery }),
      trackingName: 'Winery',
      cartItemSourceType: CART_ITEM_SOURCE_TYPES.WINERY_PAGE_WINE_PAGE_HEADER,
    })
  );
};

const getWineTypeCrumbData = (vintage) => {
  if (!isUnknownWineType(vintage.wine)) {
    const wineTypeName = getWineTypeName(vintage.wine.type_id);

    return getCrumbData({
      name: wineTypeName,
      url: explorerUrl({ wineTypeIds: [vintage.wine.type_id] }),
      trackingName: 'Wine type',
      cartItemSourceType: CART_ITEM_SOURCE_TYPES.BREADCRUMB_EXPLORE,
    });
  }
};

const getGrapeCrumbData = (vintage) => {
  const grapes = getGrapesForVintage(vintage);

  const commonProperties = {
    trackingName: 'Grape',
    cartItemSourceType: CART_ITEM_SOURCE_TYPES.BREADCRUMB_EXPLORE,
  };

  if (grapes?.length > 1) {
    return getCrumbData({
      name: t(TRANSLATIONS.blend),
      url: explorerUrl({ grapeIds: grapes.map((grape) => grape.id) }),
      ...commonProperties,
    });
  }

  return getCrumbData({
    name: grapes[0]?.name,
    url: explorerUrl({ grapeIds: [grapes[0]?.id] }),
    ...commonProperties,
  });
};

export const getBreadCrumbsDataForVintage = (vintage) => {
  const breadCrumbsData = [];
  const countryCrumb = getCountryCrumbData(vintage);
  if (countryCrumb) {
    breadCrumbsData.push(countryCrumb);
  }
  const regionCrumb = getRegionCrumbData(vintage);
  if (regionCrumb) {
    breadCrumbsData.push(regionCrumb);
  }
  const wineryCrumb = getWineryCrumbData(vintage);
  if (wineryCrumb) {
    breadCrumbsData.push(wineryCrumb);
  }
  const wineTypeCrumb = getWineTypeCrumbData(vintage);
  if (wineTypeCrumb) {
    breadCrumbsData.push(wineTypeCrumb);
  }
  const grapeCrumb = getGrapeCrumbData(vintage);
  if (grapeCrumb) {
    breadCrumbsData.push(grapeCrumb);
  }
  return breadCrumbsData;
};
