import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { track } from 'vivino-js/analytics';
import { CommonPropTypes, ComponentSizes } from 'vivino-js/commonPropTypes';
import CommunityReviewsTrackingContext from 'vivino-ui/components/CommunityReviews/CommunityReviewsTrackingContext';

import CommunityReviewPresentational from './CommunityReview.presentational';
import { CommunityReviewSkeleton } from './CommunityReview.skeleton';

export { CommunityReviewSkeleton };

const CommunityReviewContainer = ({
  review,
  isReviewedVintageYearVisible = false,
  vintageYear,
  onReviewDeleted,
  isEditable = false,
  isUserActionEnabled = false,
  size = ComponentSizes.Base,
  getReviewNote = null,
  className,
  renderReviewContentAsCard = false,
}) => {
  const trackedFrom = useContext(CommunityReviewsTrackingContext);
  const [communityReview, setCommunityReview] = useState(review);
  const [isCommentsVisible, setIsCommentsVisible] = useState(false);
  const [commentsCount, setCommentsCount] = useState(
    review?.activity?.statistics?.comments_count || 0
  );

  useEffect(() => {
    setCommunityReview(review);
  }, [review]);

  const handleCommentClick = () => {
    if (isCommentsVisible || !communityReview?.activity?.id) {
      setIsCommentsVisible(false);
    } else {
      track({
        event: 'User review - view comments',
        props: { trackedFrom },
      });

      setIsCommentsVisible(true);
    }
  };

  const handleUpdateCommentsCount = (diff) => {
    setCommentsCount((previousCommentsCount) => previousCommentsCount + diff);
  };

  return (
    <CommunityReviewPresentational
      review={communityReview}
      getReviewNote={getReviewNote}
      size={size}
      isReviewedVintageYearVisible={isReviewedVintageYearVisible}
      vintageYear={vintageYear}
      onReviewUpdated={setCommunityReview}
      onReviewDeleted={onReviewDeleted}
      isEditable={isEditable}
      isUserActionEnabled={isUserActionEnabled}
      onCommentClick={handleCommentClick}
      isCommentsVisible={isCommentsVisible}
      onUpdateCommentsCount={handleUpdateCommentsCount}
      commentsCount={commentsCount}
      className={className}
      renderReviewContentAsCard={renderReviewContentAsCard}
    />
  );
};

CommunityReviewContainer.propTypes = {
  review: PropTypes.object,
  isUserActionEnabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  size: CommonPropTypes.componentSize,
  onReviewDeleted: PropTypes.func,
  getReviewNote: PropTypes.func,
  className: PropTypes.string,

  /**
   * The vintage year on the wine page
   */
  isReviewedVintageYearVisible: PropTypes.bool,
  vintageYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  renderReviewContentAsCard: PropTypes.bool,
};

export default CommunityReviewContainer;
