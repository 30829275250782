import { ceilToNearestPrecision } from 'vivino-js/helpers/number';
const MAX_RATING = 5;
const MIN_RATING = 1;

export function calculateRatingWithPrecision({ pointerPosition, elementWidth, precision }) {
  const value = (pointerPosition / elementWidth) * MAX_RATING;

  return Math.max(
    MIN_RATING,
    Math.min(
      MAX_RATING,
      ceilToNearestPrecision({
        value,
        precision,
      })
    )
  );
}
