import { RubyLibCellarList } from '@vivino/js-web-common';

export function findMainCellar({
  userCellars,
}: {
  userCellars: RubyLibCellarList[];
}): RubyLibCellarList {
  if (!userCellars || userCellars.length === 0) {
    return null;
  }

  return userCellars[0];
}
