import { Button, ButtonTheme, useBreakpoint } from '@vivino/js-react-common-ui';
import React from 'react';
import { VerticalFlagOffIcon16 } from 'vivino-ui/icons/VerticalFlagIcons/16';
import { VerticalFlagOffIcon24 } from 'vivino-ui/icons/VerticalFlagIcons/24';

import styles from './addToWishlist.scss';

const AddToWishlistSkeleton = () => {
  const { isMobile } = useBreakpoint();
  const VerticalFlagOffIcon = () =>
    isMobile ? <VerticalFlagOffIcon16 /> : <VerticalFlagOffIcon24 />;

  return (
    <div className={styles.addToWishList}>
      <Button startIcon={<VerticalFlagOffIcon />} theme={ButtonTheme.PlainQuiet} disabled>
        <div className={styles.skeletonText} />
      </Button>
    </div>
  );
};

export default AddToWishlistSkeleton;
