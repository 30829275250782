export enum DrinkingWindowStatus {
  DrinkAtYourPace = 'drink_at_your_pace',
  Hold = 'hold',
  DrinkOrHold = 'drink_or_hold',
  DrinkNow = 'drink_now',
  PastItsPeak = 'past_its_peak',
}

export const drinkingWindowStatusMap = {
  0: DrinkingWindowStatus.DrinkAtYourPace,
  1: DrinkingWindowStatus.DrinkAtYourPace,
  2: DrinkingWindowStatus.DrinkAtYourPace,
  3: DrinkingWindowStatus.Hold,
  4: DrinkingWindowStatus.DrinkOrHold,
  5: DrinkingWindowStatus.DrinkNow,
  6: DrinkingWindowStatus.PastItsPeak,
};
